import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  Alert,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Sentry from "@sentry/react";
import Loader from "../../components/UI/Loader";
const OrganiserScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOrganiserLoggedIn, setIsOrganiserLoggedIn] = useState(() =>
    getFromLocalStorage("isOrganiserLoggedIn")
  );

  useEffect(() => {
    setIsOrganiserLoggedIn(getFromLocalStorage("isOrganiserLoggedIn"));
  }, []);
  useEffect(() => {
    if (isOrganiserLoggedIn === "true") {
      // window.location.href = "organiser-overview";
      window.location.href = "organiser-dashboard";
    }
  }, [isOrganiserLoggedIn]);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setLoading(false);
        console.log(dataResponse);
        if (dataResponse.data[0].is_event_organiser) {
          saveToLocalStorage(
            "isOrganiserLoggedIn",
            "" + dataResponse.data[0].is_event_organiser
          );
          saveToLocalStorage(
            "canUseCallout",
            "" + dataResponse.data[0].can_use_eoi_callouts
          );
          saveToLocalStorage(
            "canCreateEOI",
            "" + dataResponse.data[0].can_create_eois
          );
          saveToLocalStorage(
            "canSeeSales",
            "" + dataResponse.data[0].can_see_sales
          );

          saveToLocalStorage("email", email);
          saveToLocalStorage("password", password);
          saveToLocalStorage("Organiser", JSON.stringify(dataResponse.data));
          saveToLocalStorage("selectedEventId", dataResponse.data[0].event);
          setLoggedIn(true);
          //window.location.replace("organiser-overview");
        } else {
          setInvalid(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };
  return (
    <div className="organiser-screen">
      <div className="form-header">WHERE THE TRUCK</div>
      <div className="login-form-container">
        <Loader loading={loading} />
        {/* {loggedIn && <Redirect to="/organiser-overview" />} */}
        {loggedIn && <Redirect to="/organiser-dashboard" />}
        <Form onSubmit={handleSubmit} className="login-form">
          {invalid && (
            <div className="alert alert-danger">
              Invalid email/password. Please try again.
            </div>
          )}

          <Form.Group controlId="email" className="form-group">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="password" className="form-group">
            <Form.Label>Password</Form.Label>
            <OutlinedInput
              fullWidth
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Form.Group>

          <ReCAPTCHA
            sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
            className="recaptcha"
          />

          <Button
            type="submit"
            disabled={!validateForm()}
            className="submit-button"
          >
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default OrganiserScreen;
