// export const SQUARE_APP_ID = 'sandbox-sq0idb-IR9hqSGxypTyjgOSX9COXQ';
// export const SQUARE_ACCESS_TOKEN =
//   'EAAAEIfNmX7W6L-uF6DYnlJWy2QO2srGzuJVcNITT5Cx9jWLJ--b6ZJK254efQ87';
// export const PAYMENT_URL = 'https://connect.squareupsandbox.com/v2/payments';
// export const SQUARE_SCRIPT_URL = 'https://js.squareupsandbox.com/v2/paymentform'
// export const CURRENCY = 'AUD';
// export const SQUARE_API_VERSION = '2020-02-26';
// export const EMAIL_ORDER_NOTIFICATIONS = "yash@amfvg.com.au";
// export const LOCATION_ID = "H98T8XWZJHR3C"
// export const SQUARE_SCRIPT_JS = 'https://sandbox.web.squarecdn.com/v1/square.js';

export const SQUARE_SCRIPT_JS = "https://web.squarecdn.com/v1/square.js";
export const SQUARE_APP_ID = "sq0idp-84F3Zc9uCnuSAMbtdmHXKg";
export const SQUARE_ACCESS_TOKEN =
  "EAAAEC58deO3N5MvP9jahiPq9TcbcTXFCy--RX0LLUnKJsqUZ2ytFvr4L1ZcONuL";
export const PAYMENT_URL = "https://connect.squareup.com/v2/payments";
export const SQUARE_SCRIPT_URL = "https://js.squareup.com/v2/paymentform";
export const CURRENCY = "AUD";
export const SQUARE_API_VERSION = "2020-02-26";
export const EMAIL_ORDER_NOTIFICATIONS = "orders@wherethetruck.com.au";
export const LOCATION_ID = "QSDE4445NB0PJ";
export const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";
export const STOCK_LOCATION_ID = "LQXP0W8N70858";
export const TRANSACTION_FEE = 0.0123;
export const WTT_DELIVERY_FEE = 200;
export const DELIVERY_FEE = 7;
export const KITCHACO_DELIVERY_FEE = 0;
export const APP_FEE = 0.0577;
export const EVENT_FEE = 0.1377;
export const VENDOR_MEMBERSHIP = 499;
export const EMAIL_BOOK_CATERING = "hello@wherethetruck.com.au";
export const GOOGLE_API_KEY = "AIzaSyB_1vRZ2BJfcRoETruoolVaBW0qbUqvrH4";
export const MAILCHIMP_KEY = 'KqogytxmJIhoq_Cd3Wxqmg'
export const UNITS = [
  "Kilograms",
  "Milligrams",
  "Grams",
  "Pounds",
  "Ounces",
  "Liters",
  "Milliliters",
  "Each Unit",
];

export function consoleLog() {
  const args = [...arguments];
  for (let i = 0; i < args.length; i++) {}
}

export const VENDOR_INTRO_TEMPLATE = "vendor-intro";
