import React from "react";
import "./style.css";

const DashboardCard = ({ children, title, background, show, style }) => {
  let styles = {
    background: background !== "dark" ? "white" : "#f3f1ef",
  };
  if (style) styles = { ...styles, ...style };
  return show || show === undefined ? (
    <div className="dashboard-card" style={styles}>
      <h3 className="card-heading">{title}</h3>
      <div className="content-container">{children}</div>
    </div>
  ) : null;
};

export default DashboardCard;
