import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import axios from 'axios';
import moment from "moment";

import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import Select from "react-select"; // Import react-select

class AdminRosterPage extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") !== "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") !== "" &&
      getFromLocalStorage("isAccountantLoggedIn") !== null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== "" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    stockList: [],
    events: [],
    selectedEvent: 0,
    showSmsModal: false,
  smsMessage: "",
  selectedOrg: "",
  rephrasedMessage: "",
    showModal: false,
    showConfirmation: false,
    newVendorId: "",
    vendorToRemove: null,
    vendors: [],
    showInviteModal: false, // State to control Invite New Tanda User modal visibility
    newUserPhoneNumber: "",
    newUserEmail: "",
    newUserName: "",
    welcomeMessage: "",
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") !== ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") !== ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") !== ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") !== ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
        staffList: [],
    selectedStaff: null,
    startTime: "",
    endTime: "",
    currentVendor: null, // Tracks the selected vendor for assignment
    showAssignModal: false, // Modal state for assigning staff
    isUpdatingStockList: false,
    showUpdateConfirmation: false,
    showTruckManagerModal: false,
  selectedTruckManager: null,
  truckManagerVendor: null,
    standardMessages: [
      "Please make sure to contact IT if you need any POS hardware.",
      "Please make sure your menus are updated.",
      "Please make sure you have read your runsheets.",
      "Please make sure you have read opening times.",
      "Please make sure you have placed your stock orders in for drinks and utilities.",
      "Reminder: Verify your setup time for the event.",
      "Ensure your staff are briefed and ready.",
      "Please confirm your POS and payment options are set up.",
      "Final check: Update any last-minute changes on the app.",
    ],
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };


  fetchStaffList = () => {
    const url = config.BASE_URI + apiPaths.adminData;
    const headers = { "Content-Type": "application/json" };
    const payload = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_staff",
        },
      },
    });

    fetch(url, {
      method: "POST",
      headers: headers,
      body: payload,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
            this.setState({ staffList: result.vendors });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("Error fetching staff list:", error));
  };


  handleShowAssignModal = (vendor) => {
    console.log("Opening modal for vendor:", vendor);
    this.setState({ currentVendor: vendor, showAssignModal: true }, () => {
      console.log("Modal state:", this.state.showAssignModal);
    });
  };

  handleCloseAssignModal = () => {
    this.setState({
      currentVendor: null,
      selectedStaff: null,
      startTime: "",
      endTime: "",
      showAssignModal: false,
    });
  };

//  Truck Manager Modal 
handleShowTruckManagerModal = (vendor) => {
  console.log("Vendor selected for Truck Manager:", vendor); // Debugging log
  this.setState({ truckManagerVendor: vendor, showTruckManagerModal: true }, () => {
    this.fetchTruckManagers(); // Fetch truck managers when modal is opened
  });
};


handleCloseTruckManagerModal = () => {
  this.setState({
    truckManagerVendor: null,
    selectedTruckManager: null,
    showTruckManagerModal: false,
  });
};

assignTruckManager = () => {
  const { truckManagerVendor, selectedTruckManager } = this.state;

  if (!selectedTruckManager) {
    alert("Please select a Truck Manager.");
    return;
  }

  const url = config.BASE_URI + apiPaths.adminData;
  const headers = { "Content-Type": "application/json" };
  const payload = JSON.stringify({
    payload: {
      body: {
        query_type: "assign_truck_manager",
        vendor_id: truckManagerVendor.vendor__title,
        staff_id: selectedTruckManager.value,
        event_id: this.state.selectedEvent,
      },
    },
  });

  fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        alert("Truck Manager assigned successfully!");
        this.handleCloseTruckManagerModal();
        this.fetchStockListFromServer(); // Refresh vendor data
      } else {
        alert("Failed to assign Truck Manager: " + result.error);
      }
    })
    .catch((error) => {
      console.error("Error assigning Truck Manager:", error);
    });
};

renderTruckManagerModal() {
  const { showTruckManagerModal, staffList, selectedTruckManager, truckManagerVendor } = this.state;

  const staffOptions = staffList.map((manager) => ({
    value: manager.id, // Use appropriate keys from the API response
    label: `${manager.first_name} (${manager.last_name || "Manager"})`,
  }));

  return (
    <Modal show={showTruckManagerModal} onHide={this.handleCloseTruckManagerModal}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Truck Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {truckManagerVendor ? (
          <>
            <h5>Vendor: {truckManagerVendor.vendor__title || "N/A"}</h5>
            <Form.Group>
              <Form.Label>Select Truck Manager</Form.Label>
              <Select
                options={staffOptions}
                value={selectedTruckManager}
                onChange={(selectedOption) => this.setState({ selectedTruckManager: selectedOption })}
              />
            </Form.Group>
          </>
        ) : (
          <p>No vendor selected for Truck Manager assignment.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseTruckManagerModal}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={this.assignTruckManager}
          disabled={!selectedTruckManager}
        >
          Confirm Truck Manager
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//  END

  assignStaffToVendor = () => {
    const { currentVendor, selectedStaff, startTime, endTime } = this.state;

    if (!selectedStaff || !startTime || !endTime) {
      alert("Please select staff and provide start and end times.");
      return;
    }

    const paths = config.BASE_URI + apiPaths.adminData;
    const headers = { "Content-Type": "application/json" };
    const payload = JSON.stringify({
      payload: {
        body: {
          query_type: "assign_staff_to_vendor",
          vendor_id: currentVendor.vendor__title,
          tanda_id: selectedStaff.value,
          start_time: Math.floor(new Date(startTime).getTime() / 1000),
          end_time: Math.floor(new Date(endTime).getTime() / 1000),
          event_id:this.state.selectedEvent
        },
      },
    });

    fetch(paths, {
        method: "POST",
        headers: headers,
        body: payload,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            alert("Staff assigned successfully!");
            this.fetchStockListFromServer(); // Refresh roster
            this.handleCloseAssignModal();
          } else {
            alert(result.error);
          }
        })
        .catch((error) => console.log("Error assigning staff:", error));
    };


    renderAssignModal() {
        const { showAssignModal, staffList, currentVendor,startTime, endTime, selectedStaff } =
          this.state;
    
        const staffOptions = staffList.map((staff) => ({
          value: staff.tanda_id,
          label: `${staff.name} (${staff.event__organisation_account_name || "N/A"})`,
        }));
    
        return (
          <Modal show={showAssignModal} onHide={this.handleCloseAssignModal}>
            <Modal.Header closeButton>
              <Modal.Title>Assign Staff to Outlet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Select Staff</Form.Label>
                <Select
                  options={staffOptions}
                  value={selectedStaff}
                  onChange={(selectedOption) =>
                    this.setState({ selectedStaff: selectedOption })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={startTime}
                  onChange={(e) => this.setState({ startTime: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={endTime}
                  onChange={(e) => this.setState({ endTime: e.target.value })}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseAssignModal}>
                Close
              </Button>
              <Button variant="primary" onClick={this.assignStaffToVendor}>
                Assign Staff
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }

      renderInviteModal() {
        const {
          showInviteModal,
          newUserPhoneNumber,
          newUserEmail,
          newUserName,
          welcomeMessage,
          selectedOrg,
        } = this.state;
    
        return (
          <Modal show={showInviteModal} onHide={this.handleCloseInviteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Invite New Tanda User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  value={newUserName}
                  onChange={(e) => this.setState({ newUserName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  value={newUserEmail}
                  onChange={(e) => this.setState({ newUserEmail: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  value={newUserPhoneNumber}
                  onChange={(e) =>
                    this.setState({ newUserPhoneNumber: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Welcome Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter a welcome message"
                  value={welcomeMessage}
                  onChange={(e) =>
                    this.setState({ welcomeMessage: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group>
          <Form.Label>Invite for</Form.Label>
          <Form.Control
            as="select"
            value={selectedOrg}
            onChange={(e) => this.setState({ selectedOrg: e.target.value })}
          >
            <option value="" disabled>
              Select Organization
            </option>
            <option value="Multibrands">Multibrands</option>
            <option value="Rusiti Group">Rusiti Group</option>
          </Form.Control>
        </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseInviteModal}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleInviteNewUser}>
                Send Invitation
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }

      // Function to handle the submission of the new Tanda user invitation
      handleInviteNewUser = () => {
        const { newUserPhoneNumber, newUserEmail, newUserName, welcomeMessage ,selectedOrg} =
          this.state;
      
        if (!newUserPhoneNumber || !newUserEmail || !newUserName) {
          alert("Please fill out all the fields.");
          return;
        }

        const org_value = selectedOrg === "Multibrands" ? 1 : 2; // Determine org_value based on selection
      
        const url = config.BASE_URI + apiPaths.adminData;
        const headers = { "Content-Type": "application/json" };
        const payload = JSON.stringify({
          payload: {
            body: {
              query_type: "onboard_new_user", // Query type for onboarding new user
              phone_number: newUserPhoneNumber,
              email: newUserEmail,
              name: newUserName,
              message: welcomeMessage,
              org_value: org_value, // Include org_value
            },
          },
        });
      
        fetch(url, {
          method: "POST",
          headers: headers,
          body: payload,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              alert("User onboarded successfully!");
              this.handleCloseInviteModal();
            } else {
              alert("Failed to onboard user: " + result.error);
            }
          })
          .catch((error) => console.log("Error onboarding new user:", error));
      };

  handleCloseInviteModal = () => {
    this.setState({
      showInviteModal: false,
      newUserPhoneNumber: "",
      newUserEmail: "",
      newUserName: "",
      welcomeMessage: "",
    });
  };

  handleShowInviteModal = () => {
    this.setState({ showInviteModal: true });
  };

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_future_events",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ events: dataResponse.events });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllVendors() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendors: dataResponse.vendors });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Function to open the SMS modal
handleShowSmsModal = () => {
  this.setState({ showSmsModal: true });
};

// Function to close the SMS modal
handleCloseSmsModal = () => {
  this.setState({ showSmsModal: false, smsMessage: "", rephrasedMessage: "" });
};

// Function to rephrase the message using ChatGPT API
rephraseMessage = async () => {
  const api_key = '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'
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are an assistant that rephrases text to make it clearer and more engaging.'
          },
          {
            role: 'user',
            content: `Rephrase this message make it sound more buisness frindly with Austalian Grammer as well as better: "${this.state.smsMessage}"`
          }
        ],
        max_tokens: 500,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${api_key}`, // Ensure OPENAI_API_KEY is defined
        },
      }
    );
    const rephrasedContent = response.data.choices[0].message.content;
    this.setState({ rephrasedMessage: rephrasedContent });
  } catch (error) {
    console.error("Error rephrasing message:", error);
    alert("Failed to rephrase the message.");
  }
};

// Function to send SMS message
sendSmsToVendors = () => {
  const { rephrasedMessage, smsMessage, selectedEvent } = this.state;
  const finalMessage = rephrasedMessage || smsMessage;

  fetch(config.BASE_URI + apiPaths.adminData, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      payload: {
        body: {
          query_type: "send_sms_to_vendors",
          event_id: selectedEvent,
          message: finalMessage,
        },
      },
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        alert("SMS sent successfully!");
        this.handleCloseSmsModal();
      } else {
        alert("Failed to send SMS: " + result.error);
      }
    })
    .catch((error) => {
      console.error("Error sending SMS:", error);
    });
};

  fetchStockListFromServer = () => {
    const { superAdminEmail, superAdminPassword, selectedEvent } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "get_roster_for_event",
          event_id: parseInt(selectedEvent),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
            this.setState({ stockList: result.roster }); 
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
      isEventCoordinatorLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getKitchacoLocationData();
      this.getAllVendors();
      
  this.fetchStaffList(); // Fetch staff data when component mounts

      this.fetchStockListFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  handleEventChange = (e) => {
    this.setState({ selectedEvent: e.target.value }, () => {
      this.fetchStockListFromServer();
    });
  };

  handleVendorToggle = (index) => {
    this.setState((prevState) => {
      const newStockList = [...prevState.stockList];
      newStockList[index].selected = !newStockList[index].selected;
      return { stockList: newStockList };
    });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, newVendorId: "" });
  };

  handleShowConfirmation = (vendor) => {
    this.setState({ showConfirmation: true, vendorToRemove: vendor });
  };

  handleCloseConfirmation = () => {
    this.setState({ showConfirmation: false, vendorToRemove: null });
  };

  handleAddVendor = () => {
    const { newVendorId, selectedEvent, stockList, vendors } = this.state;
    const newVendor = vendors.find(
      (vendor) => vendor.user_ptr_id === parseInt(newVendorId)
    );
    if (newVendor) {
      var url = config.BASE_URI + apiPaths.adminData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "add_vendor_to_event",
            vendor_id: newVendor.user_ptr_id,
            event_id: selectedEvent,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            this.setState({
              stockList: [...stockList, { ...newVendor, selected: false }],
              showModal: false,
              newVendorId: "",
            });
          } else {
            alert(result.error);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  handleRemoveVendor = () => {
    const { vendorToRemove, selectedEvent, stockList } = this.state;

    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "remove_vendor_from_event",
          vendor_id: vendorToRemove.user_ptr_id,
          event_id: selectedEvent,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            stockList: stockList.filter(
              (vendor) => vendor !== vendorToRemove
            ),
            showConfirmation: false,
            vendorToRemove: null,
          });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };



  // Fetching Truck Managers

  fetchTruckManagers = () => {
    const url = config.BASE_URI + apiPaths.adminData;
    const headers = { "Content-Type": "application/json" };
    const payload = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_truck_managers",
        },
      },
    });
  
    fetch(url, {
      method: "POST",
      headers: headers,
      body: payload,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          console.log("Truck Managers List:", result.managers); // Debugging log
          this.setState({ staffList: result.managers }); // Assuming `managers` is the key for fetched data
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("Error fetching truck managers:", error));
  };

  

  renderSmsModal() {
    const { showSmsModal, smsMessage, rephrasedMessage } = this.state;
    return (
      <Modal show={showSmsModal} onHide={this.handleCloseSmsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send SMS to Vendors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="smsMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={smsMessage}
              onChange={(e) => this.setState({ smsMessage: e.target.value })}
            />
          </Form.Group>
          <Button
            variant="secondary"
            onClick={this.rephraseMessage}
            className="mb-2"
          >
            Rephrase with ChatGPT
          </Button>
          {rephrasedMessage && (
            <Form.Group controlId="rephrasedMessage">
              <Form.Label>Rephrased Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={rephrasedMessage}
                readOnly
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseSmsModal}>
            Close
          </Button>
          <Button variant="primary" onClick={this.sendSmsToVendors}>
            Send SMS
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleGenerateBarcode = (tandaId) => {
    const url = config.BASE_URI + apiPaths.adminData;
    const headers = { "Content-Type": "application/json" };
  
    const payload = JSON.stringify({
      payload: {
        body: {
          query_type: "generate_staff_barcode",
          tanda_id: tandaId, // Pass the Tanda ID for the staff member
        },
      },
    });
  
    fetch(url, {
      method: "POST",
      headers: headers,
      body: payload,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Staff barcode generated successfully!");
        } else {
          alert("Failed to generate barcode: " + result.error);
        }
      })
      .catch((error) => {
        console.error("Error generating barcode:", error);
      });
  };

  updateVendorList = () => {
    const { stockList, superAdminEmail, superAdminPassword } = this.state;
    this.setState({ isUpdatingStockList: true });

    const selectedVendors = stockList
      .filter((vendor) => !vendor.selected)
      .map((vendor) => ({
        user_ptr_id: vendor.user_ptr_id,
        title: vendor.title,
      }));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "update_vendors_in_event",
          vendors: selectedVendors,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isUpdatingStockList: false });
        if (result.success) {
          alert("Vendor list updated successfully!");
          this.fetchStockListFromServer(); // Refresh the vendor list
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        this.setState({ isUpdatingStockList: false });
        console.log("error", error);
      });
  };

  renderHome() {
    const {
      stockList, // This now holds the roster data
      events,
      selectedEvent,
      showModal,
      showConfirmation,
      newVendorId,
      vendorToRemove,
    } = this.state;
  
    // Format events for react-select dropdown
    const eventOptions = events.map((event) => ({
      value: event.id,
      label: event.name,
    }));

    const totalEventCost = stockList.reduce((eventTotal, roster) => {
        const vendorTotal = roster.schedules.reduce((vendorTotal, schedule) => {
          return vendorTotal + (schedule.cost || 0);
        }, 0);
        return eventTotal + vendorTotal;
      }, 0);
  
      
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              
              <h2>Roster Details</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              {events.length > 0 ? (
                <Form.Group controlId="eventSelect">
                  <Form.Label>Events List</Form.Label>
                  <Select
                    value={eventOptions.find(
                      (option) => option.value === selectedEvent
                    )}
                    onChange={(selectedOption) =>
                      this.handleEventChange({
                        target: { value: selectedOption.value },
                      })
                    }
                    options={eventOptions}
                    isSearchable
                  />
                </Form.Group>
              ) : (
                <p>Loading events...</p>
              )}
            </Col>
          </Row>
          <Row>
          <Col xs={12}>
          {stockList && stockList.length > 0 ? (
            stockList.map((roster, rosterIndex) => {
              // Calculate total cost for the truck (vendor)
              const totalTruckCost = roster.schedules.reduce(
                (vendorTotal, schedule) => vendorTotal + (schedule.cost || 0),
                0
              );
              const truckManager = roster.truck_manager;


              return (
                <div key={rosterIndex}>
                  <h4>Vendor: {roster.vendor__title || "N/A"}
                  {truckManager && (
                        <span className="text-muted" style={{ marginLeft: "10px" }}>
                          (Manager: {truckManager.name}, {truckManager.email})
                        </span>
                      )}
                  </h4>
                  <h5>Date: {roster.date || "N/A"}</h5>
                  <Table striped bordered hover>
  <thead>
    <tr>
      <th>Name</th>
      <th>Phone Number</th>
      <th>Start</th>
      <th>Finish</th>
      <th>Breaks</th>
      <th>Duration</th>
      <th>Cost</th>
      <th>Meal Allowance</th> {/* New column for meal allowance */}
      
    </tr>
  </thead>
  <tbody>
    {roster.schedules && roster.schedules.length > 0 ? (
      roster.schedules.map((schedule, scheduleIndex) => {
        // Calculate duration
        const startTime = schedule.start ? new Date(schedule.start * 1000) : null;
        const finishTime = schedule.finish ? new Date(schedule.finish * 1000) : null;
        const duration = startTime && finishTime 
          ? Math.round((finishTime - startTime) / (1000 * 60)) // Duration in minutes
          : null;

        // Calculate meal allowance
        const allowance = duration !== null ? Math.floor(duration / 360) * 36 : 0; // $36 for every 6 hours

        // Format the shift date
        const shiftDate = startTime
          ? `${startTime.getDate().toString().padStart(2, "0")}/${(startTime.getMonth() + 1)
              .toString()
              .padStart(2, "0")}/${startTime.getFullYear()}`
          : "N/A";

        // Handle meal allowance generation
        const handleGenerateMealAllowance = () => {
  const url = config.BASE_URI + apiPaths.adminData;
  const headers = { "Content-Type": "application/json" };

  // Format shiftDate using moment
  const shiftDate = startTime
    ? moment(startTime).format("DD MMM YYYY") // Example: 12 Jun 2024
    : "N/A";

debugger;
  const payload = JSON.stringify({
    payload: {
      body: {
        query_type: "staff_meal_allowance",
        tanda_id: schedule.user_id,
        date: shiftDate, // Pass formatted date
        allowance: allowance,
        event_id: this.state.selectedEvent, // Include event_id in the query
      },
    },
  });


  fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        alert("Meal allowance generated successfully!");
      } else {
        alert("Failed to generate meal allowance: " + result.error);
      }
    })
    .catch((error) => {
      console.error("Error generating meal allowance:", error);
    });
};

        return (
          <tr key={scheduleIndex}>
            <td>{schedule.user_details?.name || "N/A"}</td>
            <td>{schedule.user_details?.phone_number || "N/A"}</td>
            <td>
              {startTime
                ? startTime.toLocaleTimeString("en-AU", { timeZone: schedule.time_zone })
                : "N/A"}
            </td>
            <td>
              {finishTime
                ? finishTime.toLocaleTimeString("en-AU", { timeZone: schedule.time_zone })
                : "N/A"}
            </td>
            <td>
              {schedule.breaks && schedule.breaks.length > 0 ? (
                schedule.breaks.map((brk, idx) => (
                  <div key={idx}>
                    {`Start: ${
                      brk.start
                        ? new Date(brk.start * 1000).toLocaleTimeString(
                            "en-AU",
                            { timeZone: schedule.time_zone }
                          )
                        : "N/A"
                    }, Finish: ${
                      brk.finish
                        ? new Date(brk.finish * 1000).toLocaleTimeString(
                            "en-AU",
                            { timeZone: schedule.time_zone }
                          )
                        : "N/A"
                    }, Length: ${brk.length || 0} min`}
                  </div>
                ))
              ) : (
                "No Breaks"
              )}
            </td>
            <td>
              {duration !== null ? `${Math.floor(duration / 60)}h ${duration % 60}m` : "N/A"}
            </td>
            <td>
              {schedule.cost !== null ? `$${schedule.cost.toFixed(2)}` : "N/A"}
            </td>
            <td>
              {allowance > 0 ? (
                <>
                  <span>${allowance}</span>
                  <Button
                    variant="success"
                    size="sm"
                    className="ml-2"
                    onClick={handleGenerateMealAllowance}
                  >
                    Generate Allowance
                  </Button>
                </>
              ) : (
                "N/A"
              )}
            </td>
            <td>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => this.handleGenerateBarcode(schedule.user_id)}
                  >
                    Generate Barcode
                  </Button>
                </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="8">No schedules available.</td>
      </tr>
    )}
  </tbody>
</Table>

                  <td>
                            <Button
                              variant="info"
                              size="sm"
                              onClick={() => this.handleShowAssignModal(roster)}
                            >
                              Add Staff
                            </Button>

                           
                          </td>
                          <td> <Button
  variant="warning"
  size="sm"
  onClick={() => this.handleShowTruckManagerModal(roster)}
>
  Assign Truck Manager
</Button></td>
                  <h5>Total Cost for {roster.vendor__title}: ${totalTruckCost.toFixed(2)}</h5>
                </div>
              );
            })
          ) : (
            <p>No roster data available.</p>
          )}
        </Col>
        <h3>Total Event Cost: ${totalEventCost.toFixed(2)}</h3>

        {this.renderAssignModal()}
        {this.renderInviteModal()}
        {this.renderTruckManagerModal()}
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminRosterPage, {
    fallback: <ErrorFallbackComponent />,
  })
);
