import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

class AdminNavBar extends Component {
  render() {
    const {
      logout,
      isSuperAdminLoggedIn,
      isOperatorLoggedIn,
      vendorName,
      isOrganiserLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.props;
    console.log(this.props);

    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    let navbarContent;
    if (isSuperAdminLoggedIn === "true") {
      navbarContent = (
        <>
          <Nav className="justify-content-end">
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Email Trails"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin-emails-vendor">
                Emails Sent To Vendors
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-pending-event-application-screen">
                Emails Quotes Sent From Suppliers (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-customer">
                Emails Quotes Sent To Customer
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-organiser">
                Emails Sent For Expressing Interest
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-catering-clients">
                Emails Sent For Catering Proposols
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-catering-clients">
                Shared Access to EOI's 
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              active={page == "view-pending-vendors" ? true : false}
              href="/view-pending-vendors"
            >
              View EOIs
            </Nav.Link>
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Sales/Reports"
              id="basic-nav-dropdown"
            >
              {/* <NavDropdown.Item href="/admin-txn-report">
                Transactional Report
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/admin-sales-report">
                Sales Report (Old Payout Page)
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-sales-report-by-event">
                Sales Report By Event
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/admin-catering-analytics">
                Catering Analytics By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/catering-analytics">
                Catering Analytics By Vendors
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/admin-profit-and-loss-screen-by-event">
                Profit/Loss Statement By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-orders">
                Sales by Event
              </NavDropdown.Item>

              <NavDropdown.Item href="/dashboard">
                Vendor Sales Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-orders-overview">
                Vendor Sales Transactions
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-external-operators">
                Master Sales Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-site-analytics">
                Sales By Venue
              </NavDropdown.Item>
              <NavDropdown.Item href="/operator-rego-sales">
                Sales By Rego
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-payout">
                Pending Payout
              </NavDropdown.Item>
              <NavDropdown.Item href="/organiser-post-event-report-screen">
                Master Post Event Report Screen (WIP)
              </NavDropdown.Item>

              <NavDropdown.Item href="/admin-post-event-report-screen">
                Admin Master Post Event Report Screen
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-post-event-report">
                Event Organiser Post Event Report (WIP)
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-post-event-report">
                Event Organiser P&L (WIP)
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-expenses">
                Event Expenses
              </NavDropdown.Item>
             
            </NavDropdown>
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Pending Applications"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin-pending-supplier-application-screen">
                Stock Supplier Applications
              </NavDropdown.Item>
              <NavDropdown.Item href="/membership-confirmation">
                Vendor Membership Confirmation
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-pending-event-application-screen">
                Event Organiser Applications
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-catering-applications">
                Inbound Catering Queries
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-catering-multiple">
                Inbound Catering Queries Muliple Packs
              </NavDropdown.Item>

              <NavDropdown.Item href="/admin-pending-subscription">
                WTT Subscription Applications 
              </NavDropdown.Item>

              <NavDropdown.Item href="/admin-vendor-catering-applications">
                Vendor Catering Quotes
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              active={
                page == "stock-admin-approved-orders" ||
                page == "stock-admin" ||
                page == "stock-admin-stocklist" ||
                page == "stock-admin-bundle"
                  ? true
                  : false
              }
              title="Stock"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/stock-admin-approved-orders">
                Finalised Stock Orders
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-admin">
                Stock Orders
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-stock-order-screen">
                Admin Stock Order page
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-admin-stocklist">
                Vendor Pantry List
              </NavDropdown.Item>

              {/* <NavDropdown.Item href="/admin-external-operators">
                Stock Supplier Analytics (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Stock Payout Reports (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Pending Stock Quote Requests (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Paid Stock Quotes (Pending)
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/stock-catelogue-supplier-list">
                Stock Catelogue
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-sales-prediction">
                Event Stock Prediction
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-stock-analytics">
                Stock Useage By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-quotes-pending">
                Pending Quotes
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-portal-past-quotes">
                Past Quotes
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              active={
                page == "admin-create-event" || page == "admin-edit-event"
                  ? true
                  : false
              }
              title="Events"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="admin-create-event">
                Create POS Event
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-pending-menu">
              Admin Pending Menu Screen
              </NavDropdown.Item>
              
              <NavDropdown.Item href="shared-link-application-calender">
                Appliaction Overview Page
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-create-runsheets">
                Create Event Runsheet
              </NavDropdown.Item>
              
              <NavDropdown.Item href="admin-bulk-apply">
                Bulk Apply To Event
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-edit-runsheets">
                Create Event Runsheet
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-share-multiple-stalls">
                Share Multiple Stalls For Event
              </NavDropdown.Item>

              <NavDropdown.Item href="admin-edit-event">
                Edit POS Event
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-events-calender-screen">
                Event Runsheets
              </NavDropdown.Item>
              <NavDropdown.Item href="update-events">
                Update Vendors in Events
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-roster-page">
                Admin Roster Page
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-staff-details-page">
                Admin Staff Useage Page
              </NavDropdown.Item>

              <NavDropdown.Item href="admin-tanda-users">
                Admin Tanda Users
              </NavDropdown.Item>

              
              
              <NavDropdown.Item href="admin-event-calender-month">
                EVENT CALENDAR
              </NavDropdown.Item>

              <NavDropdown.Item href="admin-create-event-stock-order">
                Event Organiser StockList
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-create-event-utility-order">
                Event Organiser Utility List
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-event-menu">
                Edit/Update Event Menu
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-sots">
                Event Statement of Trades
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-pre-event-checklist-list">
                Pre Event Checklist List
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-post-event-checklist-list">
                Post Event Checklist List
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-pos-kiosk-codes">
              POS Kiosk Codes
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-incident-reports">
              Event Incident Logs
              </NavDropdown.Item>

              

              
            </NavDropdown>
          </Nav>
          <Nav.Link
            active={page == "admin-subaccount" ? true : false}
            style={{ color: "white" }}
            href="/admin-subaccount"
          >
            Create Account
          </Nav.Link>
          <Nav.Link
            style={{ color: "white" }}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Nav.Link>
        </>
      );
    } else if (isEventCoordinatorLoggedIn === "true") {
      navbarContent = (
        <>
          <Nav className="justify-content-end">
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Email Trails"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin-emails-vendor">
                Emails Sent To Vendors
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-pending-event-application-screen">
                Emails Quotes Sent From Suppliers (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-customer">
                Emails Quotes Sent To Customer
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-customer">
                Emails Sent For Expressing Interest
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-emails-customer">
                Emails Sent For Catering Proposols
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              active={page == "view-pending-vendors" ? true : false}
              href="/view-pending-vendors"
            >
              View EOIs
            </Nav.Link>
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Pending Applications"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin">
                Inbound Catering Queries
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-catering-multiple">
                Inbound Catering Queries Muliple Packs
              </NavDropdown.Item>

              <NavDropdown.Item href="/admin-vendor-catering-applications">
                Vendor Catering Quotes
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              active={
                page == "admin-create-event" || page == "admin-edit-event"
                  ? true
                  : false
              }
              title="Events"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="admin-create-event">
                Create POS Event
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-create-runsheets">
                Create Event Runsheet
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-share-multiple-stalls">
                Share Multiple Stalls For Event
              </NavDropdown.Item>
              <NavDropdown.Item href="update-events">
                Update Vendors in Events
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-edit-event">
                Edit POS Event
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-events-calender-screen">
                Event Runsheets
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-event-calender-month">
                EVENT CALENDAR
              </NavDropdown.Item>

              <NavDropdown.Item href="admin-create-event-stock-order">
                Event Organiser StockList
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-create-event-utility-order">
                Event Organiser Utility List
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-event-menu">
                Edit/Update Event Menu
              </NavDropdown.Item>
              <NavDropdown.Item href="admin-sots">
                Event Statement of Trades
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav.Link
            active={page == "admin-subaccount" ? true : false}
            style={{ color: "white" }}
            href="/admin-subaccount"
          >
            Create Account
          </Nav.Link>
          <Nav.Link
            style={{ color: "white" }}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Nav.Link>
        </>
      );
    } else if (isAccountantLoggedIn === "true") {
      navbarContent = (
        <>
          <Nav className="justify-content-end">
            <NavDropdown
              active={
                page == "admin-txn-report" || page == "admin-sales-report"
                  ? true
                  : false
              }
              title="Sales/Reports"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin-txn-report">
                Transactional Report
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-sales-report">
                Sales Report
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-sales-report-by-event">
                Sales Report By Event
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/admin-catering-analytics">
                Catering Analytics By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/catering-analytics">
                Catering Analytics By Vendors
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/admin-profit-and-loss-screen-by-event">
                Profit/Loss Statement By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-orders">
                Sales by Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboard">
                Vendor Sales Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-orders-overview">
                Vendor Sales Transactions
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-external-operators">
                Master Sales Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-site-analytics">
                Sales By Venue
              </NavDropdown.Item>
              <NavDropdown.Item href="/operator-rego-sales">
                Sales By Rego
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-payout">
                Pending Payout
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-post-event-report-screen">
                Master Post Event Report Screen
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-post-event-report">
                Event Organiser Post Event Report (WIP)
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-post-event-report">
                Event Organiser P&L (WIP)
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-expenses">
                Event Expenses
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              active={
                page == "stock-admin-approved-orders" ||
                page == "stock-admin" ||
                page == "stock-admin-stocklist" ||
                page == "stock-admin-bundle"
                  ? true
                  : false
              }
              title="Stock"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/stock-admin-approved-orders">
                Finalised Stock Orders
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-admin">
                Stock Orders
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav.Link
            style={{ color: "white" }}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Nav.Link>
        </>
      );
    } else if (isStockAdminLoggedIn === "true") {
      navbarContent = (
        <>
          <Nav className="justify-content-end">
            <NavDropdown
              active={
                page == "stock-admin-approved-orders" ||
                page == "stock-admin" ||
                page == "stock-admin-stocklist" ||
                page == "stock-admin-bundle"
                  ? true
                  : false
              }
              title="Stock"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/stock-admin-approved-orders">
                Finalised Stock Orders
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-admin">
                Stock Orders
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-stock-order-screen">
                Admin Stock Order page
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-admin-stocklist">
                Vendor Pantry List
              </NavDropdown.Item>

              <NavDropdown.Item href="/admin-external-operators">
                Stock Supplier Analytics (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Stock Payout Reports (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Pending Stock Quote Requests (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/">
                Paid Stock Quotes (Pending)
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-catelogue-supplier-list">
                Stock Catelogue
              </NavDropdown.Item>
              <NavDropdown.Item href="/admin-stock-analytics">
                Stock Useage By Event
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-quotes-pending">
                Pending Quotes
              </NavDropdown.Item>
              <NavDropdown.Item href="/stock-portal-past-quotes">
                Past Quotes
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              style={{ color: "white" }}
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Nav.Link>
          </Nav>
        </>
      );
    } else if (isOrganiserLoggedIn === "true") {
      navbarContent = (
        <>
          {window.location.pathname !== "/organiser-overview" && (
            <Navbar.Brand href={"/organiser-overview"}>
              <Image
                style={{ height: 40 }}
                src={require("../../../assets/img/back-btn.png")}
                fluid
              />
            </Navbar.Brand>
          )}

          <Nav className="justify-content-end" style={{ position: "relative" }}>
            <NavDropdown
              active={
                page == "admin-create-event" || page == "admin-edit-event"
                  ? true
                  : false
              }
              title="Sales/Reports"
              id="basic-nav-dropdown"
              style={{ marginTop: 10 }}
            >
              <NavDropdown.Item href="organiser-analytics">
                Sales By Events
              </NavDropdown.Item>
              <NavDropdown.Item href="view-past-events">
                Sales Reports / Ratings
              </NavDropdown.Item>
              <NavDropdown.Item href="view-pending-vendors">
                View All EOIs
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-site-area">
                Sales By Sites
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-payout">
                Payouts
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-expenses">
                Event Expenses
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="organiser-post-event-report">
                Event Organiser Post Event Report
              </NavDropdown.Item> */}
              <NavDropdown.Item href="organiser-contract">
                Generate Event Contract
              </NavDropdown.Item>
              <NavDropdown.Item href="organiser-subaccount">
                Organiser Subaccount
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="admin-event-calender-month">
                    Vendor Payouts For Events
                  </NavDropdown.Item> */}
            </NavDropdown>

            {/* <Nav.Link>Welcome {vendorName}</Nav.Link> */}
            <Nav.Link
              style={{
                color: "white",
                marginTop: 10,
                marginBottom: 10,
                marginRight: 50,
                marginLeft: 10,
              }}
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Nav.Link>
          </Nav>
        </>
      );
    } else if (isOperatorLoggedIn === "true") {
      navbarContent = (
        <>
          <Nav>
            <Nav.Link>Welcome {vendorName}</Nav.Link>
          </Nav>
          <Nav.Link
            style={{ color: "white" }}
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Nav.Link>
        </>
      );
    }
    return (
      <Navbar
        style={{ backgroundColor: "#2859a5", color: "white" }}
        expand="lg"
      >
        <img
          alt=""
          src={require("../../../assets/img/welcome-logo.png")}
          height="40"
          className="d-inline-block align-top"
          style={{ marginRight: 10 }}
        />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">{navbarContent}</Navbar.Collapse>
      </Navbar>
    );
  }
}

export default AdminNavBar;
