import moment from "moment";

export const generateWeeks = (startDate, endDate) => {
  const weeks = [];
  let current = new Date(startDate);

  while (current <= endDate) {
    const weekStart = new Date(current);
    const weekEnd = new Date(current);
    weekEnd.setDate(weekEnd.getDate() + 6);

    // Calculate ISO week number
    const weekNumber = moment(weekStart).isoWeek();

    weeks.push({
      label: `Week ${weekNumber} (${moment(weekStart).format("DD/MM/YYYY")} - ${moment(weekEnd).format("DD/MM/YYYY")})`,
      start: new Date(weekStart),
      end: new Date(weekEnd),
    });

    current.setDate(current.getDate() + 7); // Move to the next week
  }

  return weeks;
};

