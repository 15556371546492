import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import AdminNavBar from "../../components/UI/AdminNavBar";
import * as XLSX from "xlsx";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import CsvDownloadButton from "react-json-to-csv";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Card,
  Modal,
} from "react-bootstrap";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultsList";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventPOSChart } from "../EventOrganiserOverviewScreen/EventPOSBarGraph";
import { EventSalesBySiteGraph } from "../EventOrganiserOverviewScreen/EventSalesBySiteGraph";


import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie, Line } from "react-chartjs-2";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WeatherForecastModal from "./WeatherForecastModal";
import SalesDataGraph from "./SalesDataGraph.js";
import WeatherChart from "./WeatherChart.js";
import CostByDayChart from "./Components/CostByDayChart";
import CostByTotalHourChart from "./Components/CostByTotalHourChart";
import AverageCostPerUserChart from "./Components/AverageCostPerUserChart";
import CostByHourChart from "./Components/CostByHourChart";
import SalesByDaysChart from "./Components/SalesByDaysChart";
import Select from "react-select"; // Import react-select

class AdminOperatorOrdersScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    hourly_forcast: [],
    vendors: [],
    isLoading: false,
    isHourlyGraphLoading: false,
    isMealFreqLoading: false,
    showPassword: false,
    event1Data: {},
    event2Data: {},
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    weatherDataAvg: {},
    hourlyCost: {},
    datedHourlySales: [],
    selectedEvent: {},
    forecastData: [],
    salesByDays: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    mockData: {},
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    shifts_data: {},
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    total_vouchers_value: 0,
    totalCardOrders: 0,
    totalTransactionFees: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    subTotal: 0,
    gst: 0,
    z_reports: [],
    selectedDate: "-1",
    surge_charge: 0,
    publicHolidaySurgeCharge: 0,
    salesByPOSName: [],
    salesBySiteName:[],
    salesBySiteTotal:[],
    salesByPOSTotal: [],
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    salesInSimilarEvent: 0,
    salesByHoursCurrentYear: {},
    salesModal: false,
    ordersModal: false,
    averageModal: false,
    previousModal: false,
    refundsModal: false,
    refundsOrderModal: false,
    billModal: false,
    totalChangeColor: "",
    totalChangeSymbol: "",
    totalChangePercentage: "",
    totalLastYear: 0,
    totalCurrentYear: 0,
    total_staff_meal_allocated: 0
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getAllEvents();
    } else {
      window.location.href = "/admin";
    }
  }

  handleShow = (modalName) => {
    this.setState({ [modalName]: true });
  };

  handleClose = (modalName) => {
    this.setState({ [modalName]: false });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  renderSalesByHours(shiftData) {
    console.log("WeatherData", this.state.weatherDataAvg);
    console.log("Hourly Cost", this.state.hourlyCost);
    console.log("Shift Data:", shiftData);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "category",
          labels: shiftData.labels,
        },
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: function(context) {
              return `Hour: ${context[0].label}`;
            },
            label: (context) => {
              const datasetLabel = context.dataset.label;
              const currentValue = context.raw;
              return `${datasetLabel}: ${currentValue.toFixed(2)}`;
            },
          },
          displayColors: false,
        },
      },
    };

    return (
      <Container fluid>
        <Row>
          <Col style={{ height: "350px" }}>
            <Bar data={shiftData} options={options} />
          </Col>
        </Row>
      </Container>
    );
  }

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getMealFrequency = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }

    this.setState({ isMealFreqLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_by_vendor_graphs",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      timeout: 120000,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            meal_freq: dataResponse.meal_freq,
          });
          // var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isMealFreqLoading: false });
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getHourlySales = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isHourlyGraphLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_hourly_sales",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isHourlyGraphLoading: false });
        console.log(dataResponse);
        if (dataResponse.success == true) {
          // Assuming all_events_data contains data for the previous year
          const currentYearData = dataResponse.current_event_data;
          this.setState({
            salesByHoursCurrentYear: currentYearData,
          });
        }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isHourlyGraphLoading: false });
        this.getMealFrequency();

        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isHourlyGraphLoading: false });
      });
  };

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            forecastData: dataResponse.weather_info,
            salesByDays: dataResponse.detailed_daily_breakdown,
            hourlyCost:
              dataResponse &&
              dataResponse.shifts_data &&
              dataResponse.shifts_data.sorted_hourly_cost
                ? dataResponse.shifts_data.sorted_hourly_cost
                : {},
            weatherDataAvg: dataResponse.average_hourly_weather_data,
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales:
              dataResponse.total_refunds_cash + dataResponse.total_refunds_card,
            canceled_orders: dataResponse.canceled_orders,
            shifts_data: dataResponse.shifts_data,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalTransactionFees: dataResponse.total_transaction_fees,
            totalCardOrders: dataResponse.card_new_count,
            totalCashOrders: dataResponse.cash_new_count,
            canceled_sales_cash: dataResponse.total_refunds_cash,
            canceled_sales_card: dataResponse.total_refunds_card,
            z_reports: dataResponse.z_reports,
            total_vouchers_value: dataResponse.total_voucher_value,
            salesByVendor: dataResponse.sales_by_vendor_obj,
            surge_charge: dataResponse.total_surge_charge,
            publicHolidaySurgeCharge: dataResponse.public_holiday_surge_charge,
            salesInSimilarEvent: dataResponse.similar_events_sales,
            mockData: dataResponse.mock_comparision_result,
            hourly_forcast: dataResponse.hourly_forcast,
            total_staff_meal: dataResponse.total_staff_meal,
            total_staff_meal_allocated: dataResponse.total_staff_meal_allocated,
          });
          // Function to format selectedDate

          const formatSelectedDate = (selectedDate) => {
            const dateParts = selectedDate.split(" ");
            const year = dateParts[2];
            const month = getMonthNumber(dateParts[1]);
            const day = dateParts[0].padStart(2, "0"); // Add leading zero if needed
            return `${year}-${month}-${day}`;
          };

          // Function to get month number
          const getMonthNumber = (monthName) => {
            const months = {
              Jan: "01",
              Feb: "02",
              Mar: "03",
              Apr: "04",
              May: "05",
              Jun: "06",
              Jul: "07",
              Aug: "08",
              Sep: "09",
              Oct: "10",
              Nov: "11",
              Dec: "12",
            };
            return months[monthName];
          };

          // Logic to update datedHourlySales
          if (this.state.selectedDate !== "-1") {
            const formattedSelectedDate = formatSelectedDate(
              this.state.selectedDate
            );
            const matchedDateData = dataResponse.hourly_weather_data.find(
              (dateData) => dateData.Date === formattedSelectedDate
            );

            if (matchedDateData) {
              console.log(matchedDateData.hourly_data);
              this.setState({ datedHourlySales: matchedDateData.hourly_data });
            } else {
              this.setState({ datedHourlySales: [] });
            }
          }
        } else {
          this.setState({
            orders: [],
            tempOrders: [],
            forecastData: [],
            salesByDays: {},
            weatherDataAvg: {},
            hourly_forcast: [],
            datedHourlySales: [],
            selectedEvent: {},
            total_sales: 0,
            total_orders: 0,
            canceled_sales: 0,
            canceled_orders: 0,
            shifts_data: {},
            totalCash: 0,
            totalCard: 0,
            totalTransactionFees: 0,
            totalCardOrders: 0,
            totalCashOrders: 0,
            canceled_sales_cash: 0,
            canceled_sales_card: 0,
            z_reports: [],
            total_vouchers_value: 0,
            salesByVendor: {},
            surge_charge: 0,
            publicHolidaySurgeCharge: 0,
            salesInSimilarEvent: 0,
            mockData: {},
            hourly_forcast: [],
            event1Data: {},
            event2Data: {},
            salesByPOSName: [],
            salesByPOSTotal: [],
            salesBySiteName:[],
            salesBySiteTotal:[],
            isLoading: false,
            weatherDataAvg: {},
            hourlyCost: {},
            salesByHoursCurrentYear: {},
          });
          this.setState({ salesByVendorName: [] });
          this.setState({ salesByVendorTotal: [] });
        }

        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        let salesByPOSName = [];
        let salesByPOSTotal = [];
        let salesBySiteName = [];
        let salesBySiteTotal = [];

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_pos_obj
        )) {
          console.log(`${key}: ${salesByPOSName.push(key)}`);
          console.log(`${value}: ${salesByPOSTotal.push(value)}`);
        }
        this.setState({ salesByPOSName: salesByPOSName });
        this.setState({ salesByPOSTotal: salesByPOSTotal });

        // Sales by Site

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_site_obj
        )) {
          console.log(`${key}: ${salesBySiteName.push(key)}`);
          console.log(`${value}: ${salesBySiteTotal.push(value)}`);
        }
        this.setState({ salesBySiteName: salesBySiteName });
        this.setState({ salesBySiteTotal: salesBySiteTotal });

        this.setState({ isLoading: false });
        this.getHourlySales();

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor_obj
        )) {
          console.log(`${key}: ${salesBYVendorName.push(key)}`);
          console.log(`${value}: ${salesBYVendorTotal.push(value)}`);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });

        const event1Dates = Object.keys(
          this.state.mockData?.event_1?.daily_payment || {}
        );
        const event1Values = event1Dates.map((date) => {
          return this.state.mockData?.event_1?.daily_payment?.[date] || 0;
        });
        const event2Dates = Object.keys(
          this.state.mockData?.event_2?.daily_payment || {}
        );
        const event2Values = event2Dates.map((date) => {
          return this.state.mockData?.event_2?.daily_payment?.[date] || 0;
        });

        const event1Data = {
          labels: event1Dates,
          datasets: [
            {
              label: "Event 1",
              data: event1Values,
              fill: false,
              borderColor: "rgba(75,192,192,1)",
              lineTension: 0.1,
            },
          ],
        };

        const event2Data = {
          labels: event2Dates,
          datasets: [
            {
              label: "Event 2",
              data: event2Values,
              fill: false,
              borderColor: "rgba(255,99,132,1)",
              lineTension: 0.1,
            },
          ],
        };
        this.setState({ event1Data: event1Data, event2Data: event2Data });
      })

      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
        this.setState({
          orders: [],
          tempOrders: [],
          forecastData: [],
          salesByDays: {},
          hourlyCost: {},
          weatherDataAvg: {},
          hourly_forcast: [],
          datedHourlySales: [],
          selectedEvent: {},
          total_sales: 0,
          total_orders: 0,
          canceled_sales: 0,
          canceled_orders: 0,
          shifts_data: {},
          totalCash: 0,
          totalCard: 0,
          totalTransactionFees: 0,
          totalCardOrders: 0,
          totalCashOrders: 0,
          canceled_sales_cash: 0,
          canceled_sales_card: 0,
          z_reports: [],
          total_vouchers_value: 0,
          salesByVendor: {},
          surge_charge: 0,
          publicHolidaySurgeCharge: 0,
          salesInSimilarEvent: 0,
          mockData: {},
          hourly_forcast: [],
          event1Data: {},
          event2Data: {},
          salesByPOSName: [],
          salesByPOSTotal: [],
          isLoading: false,
          weatherDataAvg: {},
          salesByHoursCurrentYear: {},
          total_staff_meal: 0,
        });
        this.setState({ salesByVendorName: [] });
        this.setState({ salesByVendorTotal: [] });
      });
  };

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_in_event",
          email: email,
          password: password,
          event: selectedEventId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          if (dataResponse.vendors && dataResponse.vendors.length === 1) {
            const vendorId = dataResponse.vendors[0].user_ptr_id.toString();
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              this.getOrders();
            });
          } else {
            let vendorId = "0";
            vendorId = vendorId.toString(); // Corrected assignment
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              this.getOrders();
            });
          }
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      salesByDays,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      selectedDate,
      isHourlyGraphLoading,
      total_vouchers_value,
      totalTransactionFees,
      surge_charge,
      publicHolidaySurgeCharge,
      salesInSimilarEvent,
      shifts_data,
      event1Data,
      event2Data,
      hourly_forcast,
      total_staff_meal,
      total_staff_meal_allocated,
    } = this.state;

    const salesDataObj = [
      {
        selectedEvent: selectedEvent.name,
        date: selectedDate == "0" ? "All" : selectedDate,
        total_sales: total_sales,
        total_orders: total_orders,
        canceled_orders: canceled_orders,
        canceled_sales: canceled_sales,
        canceled_sales_cash: canceled_sales_cash,
        canceled_sales_card: canceled_sales_card,
        totalCash: totalCash,
        totalCard: totalCard,
        totalCashOrders: totalCashOrders,
        totalCardOrders: totalCardOrders,
        total_vouchers_value: total_vouchers_value,
        totalTransactionFees: totalTransactionFees,
      },
    ];
    const data = {
      labels: Object.keys(this.state.salesByHoursCurrentYear), // Extracting hour labels from the current year data
      datasets: [
        {
          label: "Sales By Hours",
          data: Object.values(this.state.salesByHoursCurrentYear), // Extracting sales data for current year
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
          fill: true,
        },
      ],
    };
    console.log(salesByDays);
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "hour",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    if (selectedEvent) {
    }
    let date_range = this.getDateRange(
      selectedEvent.start_date,
      selectedEvent.end_date
    );
    console.log(date_range);
    let new_total_orders = totalCashOrders + totalCardOrders;
    const averageOrder =
      Number(total_sales).toFixed(2) / Number(new_total_orders).toFixed(2);
    const jsonToXLSX = (data, filename) => {
      console.log(data);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    const filterTransaction = (data, filename) => {
      console.log(data);
      // Use Array.includes() to check if item.customer_name is in the salesByPOSName array
      const filteredData = data.filter((item) =>
        this.state.salesByPOSName.includes(item.customer_name)
      );
      console.log(this.state.salesByPOSName);
      console.log(data);
      console.log(filteredData);
      jsonToXLSX(filteredData, filename);
    };

    const labels = [];
    const currentYearData = [];
    const lastYearData = [];
    var totalChangeColor = "";
    var totalChangeSymbol = "";
    var totalChangePercentage = "";
    var totalChange = 0;
    var totalCurrentYear = 0;
    var totalLastYear = 0;
    console.log(this.state.selectedDate);
    if (salesByDays && salesByDays.event_1 && salesByDays.event_2) {
      if (this.state.selectedDate === "-1") {
        // Initialize empty arrays for event dates if daily_payment doesn't exist
        let event1Dates = salesByDays.event_1?.daily_payment
          ? Object.keys(salesByDays.event_1.daily_payment).sort()
          : [];
        event1Dates = event1Dates.sort((a, b) => {
          // Convert date strings to moment objects for comparison
          const dateA = moment(a, "DD MMM YYYY");
          const dateB = moment(b, "DD MMM YYYY");

          // Return the difference between the two dates to determine their order
          return dateA - dateB;
        });
        console.log(event1Dates);
        let event2Dates = salesByDays.event_2?.daily_payment
          ? Object.keys(salesByDays.event_2.daily_payment).sort()
          : [];
        event2Dates = event2Dates.sort((a, b) => {
          // Convert date strings to moment objects for comparison
          const dateA = moment(a, "DD MMM YYYY");
          const dateB = moment(b, "DD MMM YYYY");

          // Return the difference between the two dates to determine their order
          return dateA - dateB;
        });
        console.log(event1Dates);

        // Determine the number of days based on the longest array of dates
        const numDays = Math.max(event1Dates.length, event2Dates.length);

        for (let i = 0; i < numDays; i++) {
          labels.push(`Day ${i + 1}`);

          // Fetch sales data if the date exists, otherwise use 0
          const event1Sales = event1Dates[i]
            ? salesByDays.event_1.daily_payment[event1Dates[i]]
            : 0;
          const event2Sales = event2Dates[i]
            ? salesByDays.event_2.daily_payment[event2Dates[i]]
            : 0;

          currentYearData.push(event2Sales);
          lastYearData.push(event1Sales);
        }

        totalCurrentYear = currentYearData.reduce((acc, curr) => acc + curr, 0);
        totalLastYear = lastYearData.reduce((acc, curr) => acc + curr, 0);
        totalChangePercentage = (
          ((totalCurrentYear - totalLastYear) / totalLastYear) *
          100
        ).toFixed(2);
        totalChangeColor = totalCurrentYear >= totalLastYear ? "green" : "red";
        totalChangeSymbol = totalCurrentYear >= totalLastYear ? "▲" : "▼";
      } else {
        const selectedDateFormatted = this.state.selectedDate; // "DD MMM YYYY" format

        // Ensure we have daily_payment data for both events
        if (
          salesByDays.event_2?.daily_payment &&
          salesByDays.event_1?.daily_payment
        ) {
          const event2Dates = Object.keys(
            salesByDays.event_2.daily_payment
          ).sort();
          const dayNIndex = event2Dates.indexOf(selectedDateFormatted); // Determine "Day N"

          if (dayNIndex !== -1) {
            let event1Dates = Object.keys(
              salesByDays.event_1.daily_payment
            ).sort();
            event1Dates = event1Dates.sort((a, b) => {
              // Convert date strings to moment objects for comparison
              const dateA = moment(a, "DD MMM YYYY");
              const dateB = moment(b, "DD MMM YYYY");

              // Return the difference between the two dates to determine their order
              return dateA - dateB;
            });
            console.log(event1Dates);
            if (dayNIndex < event1Dates.length) {
              const correspondingDateLastYear = event1Dates[dayNIndex]; // Corresponding "Day N" in the previous year
              console.log(correspondingDateLastYear);
              // Collect hourly data for the selected day and the corresponding day, then sort by time
              const currentYearHourly = Object.entries(
                salesByDays.event_2.hourly_payment || {}
              )
                .filter(([key]) => key.startsWith(selectedDateFormatted))
                .sort(([a], [b]) => a.localeCompare(b));
              console.log(currentYearHourly);

              const lastYearHourly = Object.entries(
                salesByDays.event_1.hourly_payment || {}
              )
                .filter(([key]) => key.startsWith(correspondingDateLastYear))
                .sort(([a], [b]) => a.localeCompare(b));
              console.log(lastYearHourly);

              for (let i = 0; i < 24; i++) {
                labels.push(`${i.toString().padStart(2, "0")}:00`); // Ensures format like "00:00", "01:00", ..., "23:00"
              }

              // Mapping of hourly data for easier access
              const mapCurrentYearHourly = new Map(currentYearHourly);
              const mapLastYearHourly = new Map(lastYearHourly);

              labels.forEach((timePart) => {
                // Extract the hour part to match with the hourly data keys
                const hourPart = timePart.split(":")[0]; // Get the hour part, e.g., "15" from "15:00"

                // Current year data for the hour, or 0 if not found
                const currentYearValue =
                  mapCurrentYearHourly.get(
                    `${selectedDateFormatted} ${timePart}`
                  ) || 0;
                currentYearData.push(currentYearValue);

                // Last year data for the hour, or 0 if not found. Need to construct the key based on the corresponding date and hour
                const lastYearKey = `${correspondingDateLastYear} ${hourPart}:00`; // Assuming lastYearHourly keys follow the "DD MMM YYYY HH:MM" format
                const lastYearValue = mapLastYearHourly.get(lastYearKey) || 0;
                lastYearData.push(lastYearValue);
              });
            }
          }
        }
        const totalCurrentYearSales = currentYearData.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const totalLastYearSales = lastYearData.reduce(
          (acc, curr) => acc + curr,
          0
        );

        // Calculate the percentage change in total sales from the previous year to the current year
        totalChange = totalCurrentYearSales - totalLastYearSales;
        totalChangePercentage = (
          (totalChange / totalLastYearSales) *
          100
        ).toFixed(2);

        // Determine the color and symbol for the change indicator
        totalChangeColor = totalChange >= 0 ? "green" : "red";
        totalChangeSymbol = totalChange >= 0 ? "▲" : "▼";
      }
    } else {
      console.log("Sales data is not available.");
    }

    const eventOptions = events.map((event) => ({
      value: event.id,
      label: event.name,
    }));

    const customStyles = {
      control: (provided) => ({
        ...provided,
        width: 350, // Set custom width for the dropdown
        borderColor: "#ccc",
      }),
      menu: (provided) => ({
        ...provided,
        width: 350, // Set custom width for the dropdown menu
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#4a90e2" : state.isFocused ? "#f0f0f0" : "white",
        color: state.isSelected ? "white" : "black",
      }),
    };
    
    

    return (
      <div>
        {!isLoading ? (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row>
            <Col xs={12} md={6} lg={6}>
  <Form.Label>Select Event</Form.Label>
  <Form.Group controlId="exampleForm.SelectCustomSizeSm" className="search-dropdown">
    <div className="search-input">
      <Select
        // Find the selected event by comparing the id
        value={
          events
            .map((e) => ({ value: e.id.toString(), label: `${e.name} - ${e.start_date} (${e.event_status})` }))
            .find((event) => event.value === selectedEventId)
        }
        onChange={(selectedOption) => {
          const selectedEventId = selectedOption ? selectedOption.value : "0"; // Get the selected event id
          this.setState({ selectedEventId }, () => {
            this.getVendorsInEvent(); // Fetch vendors in the selected event
          });
          this.setState({ selectedDate: "-1" });
          saveToLocalStorage("selectedEventId", selectedEventId);
          saveToLocalStorage("selectedDate", "-1");
        }}
        options={events.map((e) => ({
          value: e.id.toString(),
          label: `${e.name} - ${e.start_date} (${e.event_status})`,
        }))} // Format event options for react-select
        isSearchable // Enable search functionality
        isClearable // Allow the user to clear the selection
        styles={customStyles} // Apply custom styles with wider dropdown
        placeholder="Select Event" // Placeholder for the select dropdown
        menuPlacement="auto" // Automatically adjust dropdown position
        menuPortalTarget={document.body} // Ensure dropdown renders inside a portal
        menuPosition="fixed" // Fix menu position to avoid clipping in smaller containers
      />
    </div>
  </Form.Group>
</Col>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Select Vendor</Form.Label>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedVendorId}
                    onChange={(e) => {
                      this.setState(
                        { selectedVendorId: "" + e.target.value },
                        () => {
                          this.getOrders();
                        }
                      );
                      saveToLocalStorage(
                        "selectedVendorId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {vendors && vendors.length === 1 ? (
                      <option value={vendors[0].user_ptr_id}>
                        {vendors[0].title}
                      </option>
                    ) : (
                      <>
                        <option value={0}>
                          {vendors && vendors.length > 0
                            ? "All"
                            : "No vendors assigned for the event"}
                        </option>
                        {vendors && vendors.length > 0
                          ? vendors.map((e) => (
                              <option
                                key={e.user_ptr_id}
                                value={"" + e.user_ptr_id}
                              >
                                {e.title}
                              </option>
                            ))
                          : null}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              {selectedVendorId != "0" ? (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    >
                      <>
                        <option value={"-1"}>All Days</option>
                        {date_range.map((e, zrindex) => {
                          return <option value={e}>{e}</option>;
                        })}
                      </>
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    >
                      <>
                        <option value={"-1"}>All Days</option>
                        {date_range.map((e, zrindex) => {
                          return <option value={e}>{e}</option>;
                        })}
                      </>
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>
            {!isEmpty(selectedEvent) ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <h3>{selectedEvent.name}</h3>
                  <h4>{selectedEvent.location}</h4>
                </div>

                <Row>
                  <Col xs={12} md={4} lg={4}>
                    <h5>Total Sales: ${Number(total_sales).toFixed(2)}</h5>{" "}
                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("salesModal")}
                    >
                      See Details
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <h5>Total Completed Orders: {new_total_orders}</h5>{" "}
                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("ordersModal")}
                    >
                      See Details
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <h5>
                      Total Avg Order:{" "}
                      {isNaN(averageOrder)
                        ? 0
                        : `$${Number(averageOrder).toFixed(2)}`}
                    </h5>

                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("averageModal")}
                    >
                      See Details
                    </p>
                  </Col>
                </Row>
                <Modal
                  show={this.state.salesModal}
                  onHide={() => this.handleClose("salesModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Sales Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Cash Sales: ${Number(totalCash).toFixed(2)}</p>
                    <p>Card Sales: ${Number(totalCard).toFixed(2)}</p>
                    <p>
                      Transaction Fee: $
                      {Number(totalTransactionFees).toFixed(2)}
                    </p>
                    <p>Surcharge: ${surge_charge}</p>
                    <p>GST: ${((Number(total_sales) * 10) / 100).toFixed(2)}</p>
                    <p>
                      Vouchers Value: ${Number(total_vouchers_value).toFixed(2)}
                    </p>
                    <p>
  Total Staff Meal Amount Used: ${Number(total_staff_meal).toFixed(2)} 
  (${((total_staff_meal / total_staff_meal_allocated) * 100).toFixed(2)}%)
</p>
<p>
  Total Staff Meal Amount Allocated: ${Number(total_staff_meal_allocated).toFixed(2)}
</p>
                    
                    <p>
                      Vouchers Value: ${Number(total_vouchers_value).toFixed(2)}
                    </p>
                    
                    <p>
                      Total Sales(Surcharge+Transaction Fee): $
                      {(
                        Number(total_sales) +
                        Number(totalTransactionFees) +
                        surge_charge
                      ).toFixed(2)}
                    </p>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.ordersModal}
                  onHide={() => this.handleClose("ordersModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Cash Orders: {totalCashOrders} (
                      {(
                        (totalCashOrders /
                          (totalCashOrders + totalCardOrders)) *
                        100
                      ).toFixed(2)}
                      %)
                    </p>
                    <p>
                      Card Orders: {totalCardOrders} (
                      {(
                        (totalCardOrders /
                          (totalCashOrders + totalCardOrders)) *
                        100
                      ).toFixed(2)}
                      %)
                    </p>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.averageModal}
                  onHide={() => this.handleClose("averageModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Sales Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Cash Avg: $
                      {Number(totalCashOrders) !== 0
                        ? (Number(totalCash) / Number(totalCashOrders)).toFixed(
                            2
                          )
                        : 0}
                    </p>
                    <p>
                      Card Avg: $
                      {Number(totalCardOrders) !== 0
                        ? (Number(totalCard) / Number(totalCardOrders)).toFixed(
                            2
                          )
                        : 0}
                    </p>
                  </Modal.Body>
                </Modal>
                <Row>
                  <Col xs={12} md={4} lg={4}>
                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("previousModal")}
                    >
                      Compare with previous year
                    </p>
                  </Col>
                </Row>
                <Modal
                  show={this.state.previousModal}
                  onHide={() => this.handleClose("previousModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Sales Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>This Year: ${Number(total_sales).toFixed(2)}</p>
                    <p>
                      Previous Year: ${Number(salesInSimilarEvent).toFixed(2)}
                    </p>
                    {salesInSimilarEvent !== 0 ? (
                      <>
                        {total_sales > salesInSimilarEvent ? (
                          <h5 style={{ color: "green" }}>
                            Sales was Up by{" "}
                            {(
                              ((total_sales - salesInSimilarEvent) /
                                salesInSimilarEvent) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                        ) : (
                          <h5 style={{ color: "red" }}>
                            Sales was Down by{" "}
                            {(
                              ((salesInSimilarEvent - total_sales) /
                                salesInSimilarEvent) *
                              100
                            ).toFixed(2)}
                            %
                          </h5>
                        )}
                      </>
                    ) : (
                      <h5>Can't find similar events to compare.</h5>
                    )}
                  </Modal.Body>
                </Modal>
                <Row>
                  <Col xs={12} md={4} lg={4}>
                    <h5>Refunds: ${Number(canceled_sales).toFixed(2)}</h5>{" "}
                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("refundsModal")}
                    >
                      See Details
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <h5>Refunded Orders: {canceled_orders}</h5>{" "}
                    <p
                      style={{ color: "#2959a5", cursor: "pointer" }}
                      onClick={() => this.handleShow("refundsOrderModal")}
                    >
                      See Details
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <h5>
                      Total Wages:{" "}
                      {isNaN(shifts_data.total_cost)
                        ? 0
                        : Number(shifts_data.total_cost).toFixed(2)}
                    </h5>
                    {total_sales && shifts_data.total_cost && (
                      <h5>
                        Percentage of Sales:{" "}
                        {(
                          (Number(shifts_data.total_cost) / total_sales) *
                          100
                        ).toFixed(2)}
                        %
                      </h5>
                    )}
                    <p
                      style={{ color: "#29595a", cursor: "pointer" }}
                      onClick={() => this.handleShow("billModal")}
                    >
                      See Details
                    </p>
                  </Col>
                </Row>
                <Modal
                  show={this.state.billModal}
                  onHide={() => this.handleClose("billModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Bill Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h5>
                      Total Cost:{" "}
                      {isNaN(shifts_data.total_cost)
                        ? 0
                        : Number(shifts_data.total_cost).toFixed(2)}
                    </h5>
                    <CostByDayChart shiftData={shifts_data} />
                    <CostByTotalHourChart shiftData={shifts_data} />
                    <CostByHourChart shiftData={shifts_data} />
                    <AverageCostPerUserChart shiftData={shifts_data} />
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.refundsModal}
                  onHide={() => this.handleClose("refundsModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Sales Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Refunds: ${Number(canceled_sales).toFixed(2)}</p>
                    <p>
                      Total Sales Less Refund: $
                      {Number(
                        Number(total_sales) - Number(canceled_sales)
                      ).toFixed(2)}
                    </p>
                    <p>
                      Total Cash Less Refund: $
                      {Number(
                        Number(totalCash) - Number(canceled_sales_cash)
                      ).toFixed(2)}
                    </p>
                    <p>
                      Total Card Less Refund: $
                      {Number(
                        Number(totalCard) - Number(canceled_sales_card)
                      ).toFixed(2)}
                    </p>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.refundsOrderModal}
                  onHide={() => this.handleClose("refundsOrderModal")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Sales Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Refunded Orders: ${canceled_orders}</p>
                    <p>
                      Total Orders Less Refunded Orders: $
                      {total_orders - canceled_orders}
                    </p>
                  </Modal.Body>
                </Modal>
                {this.state.total_sales !== 0 &&
                  (this.state.selectedDate === "-1" ? (
                    <div className="summary">
                      <h5>
                        <strong>Summary:</strong>
                      </h5>
                      {totalChangePercentage !== "" && (
                        <p>
                          {" "}
                          Overall your sales are{" "}
                          {totalChangeColor === "green" ? "up" : "down"} by
                          <span style={{ color: totalChangeColor }}>
                            {totalChangeSymbol}{" "}
                            {isFinite(totalChangePercentage)
                              ? Math.abs(totalChangePercentage) + "%"
                              : "N/A"}
                          </span>
                          ($ {(totalCurrentYear - totalLastYear).toFixed(2)})
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="summary">
                      <h5>
                        <strong>Summary:</strong>
                      </h5>
                      {totalChangePercentage !== "" && (
                        <p>
                          {" "}
                          For the selected day, your total sales are{" "}
                          {totalChangeColor === "green" ? "up" : "down"} by
                          <span style={{ color: totalChangeColor }}>
                            {totalChangeSymbol}{" "}
                            {isFinite(Math.abs(totalChangePercentage))
                              ? Math.abs(totalChangePercentage) + "%"
                              : "N/A"}
                          </span>
                          (${" "}
                          {isFinite(totalChange) ? totalChange.toFixed(2) : 0}
                          ), compared to the corresponding day last year.
                        </p>
                      )}
                    </div>
                  ))}

                <WeatherChart hourlyForecast={hourly_forcast} />
                <WeatherForecastModal forecastData={this.state.forecastData} />
                {/* <Row>
                                  <Col>
                    <Card onClick={this.handleShow}>
                      <Card.Body>
                        <Card.Title>Weather Details</Card.Title>
                        <WeatherForecastModal
                          forecastData={forecastData}
                          show={showWeatherModal}
                          handleClose={this.handleClose}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  </Row> */}
              </>
            ) : null}
            <Row>
              <Col>
                <CsvDownloadButton
                  data={salesDataObj}
                  filename="sales_analytics.csv"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2959a5",
                    borderColor: "#2959a5",
                    borderRadius: "5px",
                    color: "#ffffff",
                    fontSize: "14px", // Reduced font size uniformly for all buttons
                    padding: "10px 20px",
                    height: "50px", // Uniform height
                    width: "250px", // Uniform width
                  }}
                  onClick={() => jsonToXLSX(salesDataObj, "sales_analytics")}
                >
                  Download Sales Data
                </CsvDownloadButton>
              </Col>
              <Col>
                <CsvDownloadButton
                  data={tempOrders}
                  filename="sales_transactions.csv"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2959a5",
                    borderColor: "#2959a5",
                    borderRadius: "5px",
                    color: "#ffffff",
                    fontSize: "14px", // Reduced font size uniformly for all buttons
                    padding: "10px 20px",
                    height: "50px", // Uniform height
                    width: "250px", // Uniform width
                  }}
                  onClick={() =>
                    filterTransaction(tempOrders, "sales_transactions")
                  }
                >
                  Download Transactions Data
                </CsvDownloadButton>
              </Col>
              <Col>
                <CsvDownloadButton
                  data={this.state.meal_freq}
                  filename="itemised_meal_report.csv"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2959a5",
                    borderColor: "#2959a5",
                    borderRadius: "5px",
                    color: "#ffffff",
                    fontSize: "14px", // Reduced font size uniformly for all buttons
                    padding: "10px 20px",
                    height: "50px", // Uniform height
                    width: "250px", // Uniform width
                  }}
                  onClick={() =>
                    jsonToXLSX(this.state.meal_freq, "itemised_meal_report")
                  }
                >
                  Download itemised Meal Data
                </CsvDownloadButton>
              </Col>
            </Row>
            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>SALES BY Sites</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <EventSalesBySiteGraph
                        posName={this.state.salesBySiteName}
                        posSalesData={this.state.salesBySiteTotal}
                        isBool={true}
                      />
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>SALES BY VENDORS</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <EventOrganiserBarGraph
                        vendorName={this.state.salesByVendorName}
                        vendorData={this.state.salesByVendorTotal}
                        isBool={true}
                      />
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>SALES BY POS</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <EventPOSChart
                        posName={this.state.salesByPOSName}
                        posSalesData={this.state.salesByPOSTotal}
                        isBool={true}
                      />
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>SALES BY HOURS</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {this.state.total_sales &&
                      (!this.state.isHourlyGraphLoading ? (
                        <React.Fragment>
                          <Row>
                            <Col xs={12} md={12} lg={12}>
                              {this.renderSalesByHours(data)}
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={require("../../assets/img/loading.gif")}
                            alt="Loading"
                          />
                        </div>
                      ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>
                      {this.state.selectedDate === "-1"
                        ? "SALES BY DAYS"
                        : "SALES BY HOUR COMPARISON"}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <SalesByDaysChart
                          salesByDays={salesByDays}
                          selectedDate={this.state.selectedDate}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>VENDOR ITEM SALES</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {this.state.selectedVendorId != undefined &&
                    this.state.selectedVendorId != 0 ? (
                      <Row>
                        <Col lg={{ span: 8, offset: 2 }}>
                          <EventOrganiserVendorsPieChart
                            vendorName={this.state.vendorName}
                            vendorData={this.state.meal_freq}
                          ></EventOrganiserVendorsPieChart>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col lg={{ span: 8, offset: 2 }}>
                          <EventOrganiserVendorsPieChart
                            vendorName={this.state.salesByVendorName}
                            vendorData={this.state.salesByVendorTotal}
                            isBool={true}
                          ></EventOrganiserVendorsPieChart>
                        </Col>
                      </Row>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="container-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>ITEMS SOLD</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {!isHourlyGraphLoading ? (
                      <div className="my-3">
                        {this.state.newMealFrequency[0] && (
                          <TableData
                            tableData={this.state.newMealFrequency}
                          ></TableData>
                        )}
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={require("../../assets/img/loading.gif")}
                        ></img>
                      </div>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Container>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderOrderStatusChangeModal() {
    const {
      isOpenOrderStatusChangeModal,
      selectedOrder,
      selectedOrderNewStatus,
      isLoadingChangeOrderStatus,
    } = this.state;

    return (
      <>
        {selectedOrder != null && selectedOrderNewStatus != "" ? (
          <Modal
            show={isOpenOrderStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenOrderStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Order #{selectedOrder.id} to {selectedOrderNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Order #{selectedOrder.id} to{" "}
                {selectedOrderNewStatus}? This will also send a message to the
                customer.
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeOrderStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeOrderStatusOnServer(
                        selectedOrder.id,
                        selectedOrderNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenOrderStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminOperatorOrdersScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
