import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import * as Sentry from "@sentry/react";
import { Redirect } from "react-router-dom";

import axios from "axios";

import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";

class VendorPublicLiablityWithIntrestedParty extends Component {
  state = {
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    selectedBookingNewStatus: "",
    selectedBooking: null,
    isOpenBookingStatusChangeModal: false,
    isOpenBookingStatusChangeModal: false,
    isLoadingChangeBookingStatus: false,
    isLoadingChangeBookingStatus: false,
    isLoadingSendingVendorRequest: false,
    vendorCateringPacks: [],
    selectedCateringPack: {},
    requestSent: [],
    isOpenCustomerEmailDetails: false,
    requestSentIds: [],
    isLoadingVendor: false,
    searchVendor: "",
    searchNonCuisineVendor: "",
    vendorListSearchDisplay: "none",
    nonCuisineVendorListSearchDisplay: "none",
    selectedCuisine: "",
    adminNotesChanged: [],
    isLoading: false,
    requestState: "all",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }


  componentDidMount() {
    try {
      // Check if the operator is logged in
      const isOperatorLoggedIn = getFromLocalStorage("isOperatorLoggedIn");
  
      if (isOperatorLoggedIn !== "true") {
        // If not logged in, redirect to login
        window.location.href = "/operator-login";
        return;
      }
  
      // Fetch vendor IDs if logged in
      this.fetch_vendor_ids();
    } catch (error) {
      // Log the error for debugging
      console.error("Error in componentDidMount:", error);
      Sentry.captureException(error);
  
      // Redirect to the login page on error
      window.location.href = "/operator-login";
    }
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.getDashboardData(this.state.requestState);
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getDashboardData = (vendor_ids) => {
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_pending_public_liablity_with_intrested_party",
          vendor_ids: vendor_ids,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.pending_sots });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeBookingStatus = (e, booking) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var newStatus = e.target.value;
    if (newStatus == "finalised") {
      if (booking.payment_status != "complete") {
        alert(
          "Error! Booking cannot be finalised until payment is completed by the customer."
        );
        return;
      }
    }
    this.setState(
      {
        selectedBooking: booking,
        selectedBookingNewStatus: e.target.value,
        isOpenBookingStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeBookingStatus: false });
      }
    );
  };

  getVendorCateringPacks = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendorCateringPacks: dataResponse.catering_packs });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  sendEmailToCustomer = (selectedBooking, selectedCateringPack) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_email_to_customer",
          booking_request_id: selectedBooking.id,
          catering_pack_id: selectedCateringPack.id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to the customer.");
          this.setState({
            isOpenCustomerResponseModal: false,
            reviewEmail: false,
          });
        } else {
          alert("Something went wrong! Please try again");

          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  setAdminNotes = (item, notes, index) => {
    let start = new Date().valueOf();
    const { bookingRequests, adminNotesChanged } = this.state;
    let tempNotesChanged = adminNotesChanged;
    let tempBookingRequest = bookingRequests;
    tempBookingRequest[index].admin_notes = notes;
    tempNotesChanged.push(tempBookingRequest[index].id);

    this.setState(
      {
        bookingRequests: tempBookingRequest,
        adminNotesChanged: tempNotesChanged,
      },
      () => {
        let end = new Date().valueOf();
      }
    );
  };

  render() {
    const { isOperatorLoggedIn } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {isOperatorLoggedIn == "true" ? (
          this.renderHome()
        ) : (
          <Redirect to="/operator-login" />
        )}
        <Container></Container>
      </div>
    );
  }

  handleFileChange = (event, booking) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    // Call the uploadSot function with the selected file and booking information
    this.uploadSot(file, booking);
  };

  uploadSot = async (file, bookingId) => {
    console.log("uploading sot", file, bookingId.id);
    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bookingId", bookingId.id);

    // Make a POST request to your Django API endpoint
    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.UploadPublicLiablity,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success === true) {
        alert("Public Liablity With Intrested Party Uploaded Successfully.");
        window.location.reload();
      } else {
        alert("Vendor Details could not be added. Please try again.");
        window.location.reload();
      }
    } catch (error) {
      alert("Vendor Details could not be added. Please try again.");
      window.location.reload();
    }
  };

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
          this.getDashboardData(result.stalls);
        } else {
          alert(result.error);
        }
      });
  };

  renderNavBar() {
    const { isOperatorLoggedIn } = this.state;
    if (isOperatorLoggedIn == "true") {
      // Redirect to the "/apply-events" route if isOperatorLoggedIn is "true"
      return <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn} />;
    } else {
      // Render the navigation bar if isOperatorLoggedIn is not "true"
      return <Redirect to="/operator-login" />;
    }
  }

  renderHome() {
    const {
      bookingRequests,
      isLoading,
      pageSelected,
      vendor_ids,
      selectedVendorId,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h2>WTT Public Liablity With Intrest to Party Portal</h2>
                  <h3>
                    Operator{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].first_name}{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
                  </h3>
                </Col>
              </Row>

              <Row className="table-header-row">
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Stall Name
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Event Name
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Due Date
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Document Status
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  View/Upload Public Liablity With Intrest to Party
                </Col>
              </Row>
              {bookingRequests.map((item, requestIndex) => {
                let cuisine_array = [...new Set(item.cuisine_array)];
                return (
                  <Accordion key={item.id}>
                    <>
                      <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                        <Row className="table-row">
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Stall Name</b>
                              {item.vendor__title}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Event Name</b>
                              {item.event__name}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Due Date</b>
                              {" " +
                                moment(item.due_date).format("DD-MMM-YYYY")}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Status</b>
                              {item.public_liablity_with_intrested_party ===
                              "" ? (
                                <span style={{ color: "red" }}>
                                  {"Pending"}
                                </span>
                              ) : (
                                <span style={{ color: "green" }}>
                                  {"Completed"}
                                </span>
                              )}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            {item.public_liablity_with_intrested_party ===
                            "" ? (
                              <span>
                                <b className="mobile-only">Upload Sot</b>
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={(e) =>
                                    this.handleFileChange(e, item, item.id)
                                  }
                                />
                              </span>
                            ) : (
                              <span style={{ color: "green" }}>
                                <a
                                  className="form-link"
                                  href={
                                    AWS_URL + "/" + item.statement_of_trade_file
                                  }
                                  target="_blank"
                                >
                                  Public Liablity With Intrest to Party
                                </a>
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </>
                  </Accordion>
                );
              })}
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col xs={2} md={2} lg={2}>
                  <p>
                    <b>Showing Page:</b>
                  </p>
                </Col>
                <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                  <div style={{ width: "98%" }}>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected - 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                      <Pagination.Item active>{pageSelected}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected + 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

export default VendorPublicLiablityWithIntrestedParty;
