import React, { useEffect, useState } from "react";
import { deleteFromLocalStorage, getFromLocalStorage } from "../../store";
import AdminNavBar from "../../components/UI/AdminNavBar";
import "./style.css";
import DashboardCard from "../../components/Dashboard/dashboard-card";
import moment from "moment";
import axios from "axios";
import { apiPaths } from "../../services/apiPath";
import apiConfig from "../../services/apiConfig";
import Loader from "../../components/UI/Loader";
import { ButtonGroup, Tab, Tabs, ToggleButton } from "react-bootstrap";

import { Button, Select } from "antd";
import { Bar, Pie } from "react-chartjs-2";
import { Autocomplete, styled, TextField } from "@mui/material";
import { Paper } from "material-ui";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const OrganiserDashboard = () => {
  const [isSuperAdminLoggedIn, setIsSuperAdminLoggedIn] = useState(
    getFromLocalStorage("isSuperAdminLoggedIn") ?? "false"
  );
  const [isOrganiserLoggedIn, setIsOrganiserLoggedIn] = useState(
    getFromLocalStorage("isOrganiserLoggedIn") ?? "false"
  );
  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(
    getFromLocalStorage("isOperatorLoggedIn") ?? "false"
  );
  const [vendorName, setVendorName] = useState(() =>
    getFromLocalStorage("vendorName") != "undefined" &&
    getFromLocalStorage("vendorName") != "" &&
    getFromLocalStorage("vendorName") != null
      ? getFromLocalStorage("vendorName")
      : ""
  );

  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Weekly");
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");

  const logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");

    setIsSuperAdminLoggedIn(false);
    setIsOperatorLoggedIn(false);
    setIsOrganiserLoggedIn(false);
    window.location.replace("/events");
  };

  const getAllEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = apiConfig.BASE_URI + apiPaths.adminData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events_for_stock",
          operator_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
        },
      },
    });
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        setEvents(dataResponse.events ?? []);
      });
  };

  const getDashboadData = async (eventId) => {
    setLoading(true);
    const organiserData = JSON.parse(getFromLocalStorage("Organiser"));
    // if(organiserData && organiserData[0] && organiserData[0].id)
    const body = {
      query_type: "get_dashboard_data_organisers",
      organiser_id: organiserData[0].id,
      filter: selectedFilter,
    };

    body.event_id = selectedFilter === "Event Mode" && eventId ? eventId : "0";
    body.event_mode = selectedFilter === "Event Mode";

    try {
      const response = await axios.post(
        apiConfig.BASE_URI + apiPaths.organiserData,
        {
          payload: {
            body: body,
          },
        }
      );
      if (!response || !response.data || !response.data.success)
        alert("Something went wrong. Refrsh the page to Try again.");
      else setDashboardData(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert("Something went wrong. Refrsh the page to Try again.");
    }
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    getDashboadData();
  }, [selectedFilter]);

  const currentYearSales =
    dashboardData &&
    dashboardData.yearly_sales &&
    dashboardData.yearly_sales.current_year_sales
      ? dashboardData.yearly_sales.current_year_sales
      : 0;
  const currentWeekSales =
    dashboardData &&
    dashboardData.weekly_sales &&
    dashboardData.weekly_sales.current_week_sales
      ? dashboardData.weekly_sales.current_week_sales
      : 0;

  const prevYearSales =
    dashboardData &&
    dashboardData.last_year_sales &&
    dashboardData.last_year_sales.last_week_sales
      ? dashboardData.last_year_sales.last_week_sales
      : 0;
  const prevWeekSales =
    dashboardData &&
    dashboardData.last_week_sales &&
    dashboardData.last_week_sales.last_week_sales
      ? dashboardData.last_week_sales.last_week_sales
      : 0;
  // console.log(currentYearSales, prevYearSales, yearlyPercentage);
  const yearlyPercentage = !prevYearSales
    ? 100
    : isNaN(
        ((Number(currentYearSales) - Number(prevYearSales)) /
          Number(prevYearSales)) *
          100
      )
    ? 0
    : ((Number(currentYearSales) - Number(prevYearSales)) /
        Number(prevYearSales)) *
      100;
  const weeklyPercentage = !prevWeekSales
    ? 100
    : isNaN(
        ((Number(currentWeekSales) - Number(prevWeekSales)) /
          Number(prevWeekSales)) *
          100
      )
    ? 0
    : ((Number(currentWeekSales) - Number(prevWeekSales)) /
        Number(prevWeekSales)) *
      100;

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const foodItemLables =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.top_10_items_week
        ? dashboardData.top_10_items_week.map((item) => item.event_meal__name)
        : []
      : dashboardData && dashboardData.top_10_items
      ? dashboardData.top_10_items.map((item) => item.event_meal__name)
      : [];
  const foodItemData =
    selectedFilter !== "Event Mode"
      ? dashboardData && dashboardData.top_10_items_week
        ? dashboardData.top_10_items_week.map((item) => item.total_quantity)
        : []
      : dashboardData && dashboardData.top_10_items
      ? dashboardData.top_10_items.map((item) => item.total_quantity)
      : [];

  const foodItemChartData = {
    labels: foodItemLables,
    datasets: [
      {
        label: "Top 10 Selling Items",
        data: foodItemData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD94",
          "#C9CBCF",
          "#36C1A5",
          "#FF6684",
        ],
      },
    ],
  };

  const topVendorsLables =
    dashboardData && dashboardData.top_10_vendors
      ? dashboardData.top_10_vendors.map((item) => item.vendor_title)
      : [];
  const topVendorsData =
    dashboardData && dashboardData.top_10_vendors
      ? dashboardData.top_10_vendors.map((item) => item.total_sales)
      : [];

  const topVendorsChartData = {
    labels: topVendorsLables,
    datasets: [
      {
        label: "Top Performing Vendors",
        data: topVendorsData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FFCD94",
          "#C9CBCF",
          "#36C1A5",
          "#FF6684",
        ],
      },
    ],
  };

  const topVendorsChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  return (
    <div>
      <AdminNavBar
        style={{ margin: "auto" }}
        isSuperAdminLoggedIn={isSuperAdminLoggedIn}
        isOrganiserLoggedIn={isOrganiserLoggedIn}
        logout={logout}
        vendorName={vendorName}
      ></AdminNavBar>

      <div className="dashboard-wrapper">
        <h1 className="vendor-header">Organiser Dashboard</h1>
        <ButtonGroup className="mb-3 custom-button-group">
          {["Yearly", "Weekly", "Event Mode"].map((filter) => (
            <ToggleButton
              key={filter}
              type="radio"
              variant="outline-primary"
              value={filter}
              checked={selectedFilter === filter}
              onChange={() => handleFilterChange(filter)}
            >
              {filter}
            </ToggleButton>
          ))}
        </ButtonGroup>
        {selectedFilter === "Event Mode" ? (
          <div
            style={{
              padding: 16,
              display: "flex",
              gap: 4,
              flexDirection: "column",
            }}
          >
            <div className="autocomplete-container">
              <Autocomplete
                options={events}
                getOptionLabel={(option) => `${option.name}`}
                // sx={{ width: this.state.width }}
                renderInput={(params) => (
                  <>
                    <p className="text-white" style={{ color: "white" }}>
                      Select Event:
                    </p>
                    <TextField {...params} variant="standard" />
                  </>
                )}
                onChange={(e, newValue) => {
                  const id = newValue ? newValue.id : "";
                  const eventName = newValue ? newValue.name : "";

                  setSelectedEventName(eventName);
                  setSelectedEventId(id);
                  getDashboadData(id);
                }}
                value={
                  events.find((event) => event.id === selectedEventId) || null
                }
              />
            </div>
          </div>
        ) : null}

        <div className="dashboard-container">
          <Loader loading={loading} />
          {selectedFilter === "Event Mode" ? (
            <div className="dashboard-section">
              {selectedEventId && dashboardData && dashboardData.event_sales ? (
                <>
                  <DashboardCard
                    style={{
                      maxHeight: 300,
                    }}
                    title={"Total Event Sales"}
                  >
                    <div></div>
                    <div>
                      <p className="text-green bg-red-500">
                        Event Sales:{" "}
                        {dashboardData &&
                        dashboardData.event_sales &&
                        dashboardData.event_sales.current_event_sales
                          ? "$ " + dashboardData.event_sales.current_event_sales
                          : "NA"}
                      </p>
                      <p className="text-green">
                        Event Orders:{" "}
                        {dashboardData &&
                        dashboardData.event_sales &&
                        dashboardData.event_sales.current_event_order_count
                          ? "$ " +
                            dashboardData.event_sales.current_event_order_count
                          : "NA"}
                      </p>
                      {/* <p className="text-green">
                    Total Card Sales:{" "}
                    {dashboardData &&
                    dashboardData.yearly_sales &&
                    dashboardData.yearly_sales.current_year_order_count
                      ? dashboardData.yearly_sales.current_year_order_count
                      : 0}
                  </p>
                  <p className="text-green">
                    Transaction Fee:{" "}
                    {dashboardData &&
                    dashboardData.yearly_sales &&
                    dashboardData.yearly_sales.current_year_order_count
                      ? dashboardData.yearly_sales.current_year_order_count
                      : 0}
                  </p>
                  <p className={"text-green"}>
                    The highest sales occur at 18:00, generating a total of
                    $507.
                  </p> */}
                    </div>
                  </DashboardCard>
                  <DashboardCard
                    style={{
                      maxHeight: 300,
                    }}
                    title={"Event Payout"}
                  >
                    <div></div>
                    <div>
                      <p className="text-green bg-red-500">
                        Total Payout:{" "}
                        {dashboardData &&
                        dashboardData.event_payout_total &&
                        dashboardData.event_payout_total.total_payout
                          ? "$ " + dashboardData.event_payout_total.total_payout
                          : "NA"}
                      </p>
                    </div>
                  </DashboardCard>
                  <DashboardCard
                    title={`Top 10 Performing Vendors (${selectedEventName ??
                      ""})`}
                  >
                    <ul>
                      {dashboardData && dashboardData.top_10_vendors
                        ? dashboardData.top_10_vendors.map((item, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="flex" style={{ gap: 6 }}>
                                <p className="serial">#{i + 1}</p>
                                <p className="item-title">
                                  {item.vendor_title}
                                </p>
                              </div>
                              <p className="total-sold">
                                Total Sales: $ {item.total_sales}
                              </p>
                            </li>
                          ))
                        : null}
                    </ul>
                    <Bar
                      options={topVendorsChartOptions}
                      data={topVendorsChartData}
                    />
                  </DashboardCard>
                  <DashboardCard
                    title={`Top 10 Food Items (${selectedEventName ?? ""})`}
                  >
                    <ul>
                      {dashboardData && dashboardData.top_10_items
                        ? dashboardData.top_10_items.map((item, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="flex" style={{ gap: 6 }}>
                                <p className="serial">#{i + 1}</p>
                                <p className="item-title">
                                  {item.event_meal__name}
                                </p>
                              </div>
                              <p className="total-sold">
                                Total Sold: {item.total_quantity}
                              </p>
                              {/* <p>Build Cost: $1.80</p>
                        <p>Production Cost: N/A</p> */}
                            </li>
                          ))
                        : null}
                    </ul>
                    <Pie data={foodItemChartData} />
                  </DashboardCard>
                </>
              ) : (
                <p className="text-white" style={{ color: "white" }}>
                  Select an Event to see Details
                </p>
              )}
            </div>
          ) : (
            <>
              <div className="dashboard-section">
                <DashboardCard
                  style={{ maxHeight: 300, gridColumn: "1 / span 2" }}
                  show={selectedFilter === "Yearly"}
                  title={"Total Sales for the Year " + new Date().getFullYear()}
                >
                  <p className="text-green">
                    Sales:{" "}
                    {dashboardData &&
                    dashboardData.yearly_sales &&
                    dashboardData.yearly_sales.current_year_sales
                      ? "$ " + dashboardData.yearly_sales.current_year_sales
                      : "NA"}
                  </p>
                  <p className="text-green">
                    Orders:{" "}
                    {dashboardData &&
                    dashboardData.yearly_sales &&
                    dashboardData.yearly_sales.current_year_order_count
                      ? dashboardData.yearly_sales.current_year_order_count
                      : 0}
                  </p>
                  <div className="flex items-center" style={{ gap: 6 }}>
                    <div className={yearlyPercentage < 0 ? "down" : "up"}></div>
                    <p
                      className={
                        yearlyPercentage < 0 ? "text-red" : "text-green"
                      }
                    >
                      {yearlyPercentage < 0 ? "Down" : "Up"} by{" "}
                      {Math.abs(yearlyPercentage).toFixed(2) + "%"} in
                      comparison to last year{" "}
                    </p>
                  </div>
                </DashboardCard>
                <DashboardCard
                  style={{ maxHeight: 300, gridColumn: "1 / -1" }}
                  show={selectedFilter === "Weekly"}
                  title={`Top Weekly Sales (Week ${moment().week()} of ${new Date().getFullYear()})`}
                >
                  <p className="text-green">
                    Sales: {currentWeekSales ? "$ " + currentWeekSales : "NA"}
                  </p>
                  <p className="text-green">
                    Orders:{" "}
                    {dashboardData &&
                    dashboardData.weekly_sales &&
                    dashboardData.weekly_sales.current_week_order_count
                      ? dashboardData.weekly_sales.current_week_order_count
                      : 0}
                  </p>
                  <div className="flex items-center" style={{ gap: 6 }}>
                    <div className={weeklyPercentage < 0 ? "down" : "up"}></div>
                    <p
                      className={
                        weeklyPercentage < 0 ? "text-red" : "text-green"
                      }
                    >
                      {weeklyPercentage < 0 ? "Down" : "Up"} by{" "}
                      {Math.abs(weeklyPercentage) + "%"} in comparison to last
                      week{" "}
                    </p>
                  </div>
                </DashboardCard>
                <DashboardCard
                  style={{ gridColumn: "1 / -1" }}
                  show={selectedFilter === "Weekly"}
                  title={`Top 10 Performing Vendors (Week ${moment().week()} of ${new Date().getFullYear()})`}
                >
                  <ul>
                    {dashboardData && dashboardData.top_10_vendors
                      ? dashboardData.top_10_vendors.map((item, i) => (
                          <li
                            key={i}
                            className="card-list-item"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="flex" style={{ gap: 6 }}>
                              <p className="serial">#{i + 1}</p>
                              <p className="item-title">{item.vendor_title}</p>
                            </div>
                            <p className="total-sold">
                              Total Sales: $ {item.total_sales}
                            </p>
                          </li>
                        ))
                      : null}
                  </ul>
                  <Bar
                    options={topVendorsChartOptions}
                    data={topVendorsChartData}
                  />
                </DashboardCard>
                <DashboardCard
                  show={selectedFilter === "Weekly"}
                  title={`Top 10 Food Items (Week ${moment().week()} of ${new Date().getFullYear()})`}
                >
                  <ul>
                    {dashboardData && dashboardData.top_10_items_week
                      ? dashboardData.top_10_items_week.map((item, i) => (
                          <li
                            key={i}
                            className="card-list-item"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="flex" style={{ gap: 6 }}>
                              <p className="serial">#{i + 1}</p>
                              <p className="item-title">
                                {item.event_meal__name}
                              </p>
                            </div>
                            <p className="total-sold">
                              Total Sold: {item.total_quantity}
                            </p>
                            {/* <p>Build Cost: $1.80</p>
                        <p>Production Cost: N/A</p> */}
                          </li>
                        ))
                      : null}
                  </ul>
                  <Pie data={foodItemChartData} />
                </DashboardCard>
                <DashboardCard
                  style={
                    selectedFilter === "Yearly"
                      ? { gridColumn: "1 / span 2" }
                      : null
                  }
                  title={`Follow-Up Tasks`}
                >
                  {/* content */}
                  <DashboardSubCard title="Vendors Required to Upload Documentation">
                    <ul>
                      {/* <li
                    className="card-list-item"
                    style={{ alignItems: "center" }}
                  >
                    <img
                      src="https://wtt-aws-bucket.s3.amazonaws.com/trucks_avatars/modified_logo_0_831"
                      style={{
                        borderRadius: "10px",
                        border: "0.5px solid #ccc",
                        width: "36px",
                        height: "36px",
                        objectFit: "cover",
                      }}
                    />
                    <p className="vendor-title">Chatime Trailer</p>
                  </li> */}
                      {dashboardData && dashboardData.followup_tasks
                        ? dashboardData.followup_tasks.map((vendor) => (
                            <li
                              key={vendor.vendor_id}
                              className="card-list-item"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="vendor-title"
                                style={{ maxWidth: "50%" }}
                              >
                                {vendor.vendor_title}
                              </p>

                              {/* <p className="total-sold">{sot.vendor__title}</p> */}
                            </li>
                          ))
                        : "NA"}
                    </ul>
                  </DashboardSubCard>
                  <DashboardSubCard title="Contracts to Sign">
                    <ul>
                      <li
                        className="card-list-item"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          src="https://wtt-aws-bucket.s3.amazonaws.com/trucks_avatars/modified_logo_0_831"
                          style={{
                            borderRadius: "10px",
                            border: "0.5px solid #ccc",
                            width: "36px",
                            height: "36px",
                            objectFit: "cover",
                          }}
                        />
                        <p className="vendor-title">Chatime Trailer</p>
                      </li>
                    </ul>
                  </DashboardSubCard>
                  <DashboardSubCard title="SOTs to Review">
                    <ul>
                      {dashboardData && dashboardData.sot_to_review
                        ? dashboardData.sot_to_review.map((sot, i) => (
                            <li
                              key={i}
                              className="card-list-item"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                className="vendor-title"
                                style={{ maxWidth: "50%" }}
                              >
                                {sot.event__name}
                              </p>
                              <p
                                className="total-sold"
                                style={{ textAlign: "left" }}
                              >
                                {sot.vendor__title}
                              </p>
                            </li>
                          ))
                        : "NA"}
                    </ul>
                  </DashboardSubCard>
                </DashboardCard>
                <DashboardCard
                  style={
                    selectedFilter === "Weekly"
                      ? { gridColumn: "1 / span 2" }
                      : null
                  }
                  show={selectedFilter === "Weekly"}
                  title={`Top 5 Site Areas (Week ${moment().week()} of ${new Date().getFullYear()})`}
                >
                  {/* content */}
                </DashboardCard>
              </div>
              <div className="dashboard-section">
                <DashboardCard
                  show={selectedFilter === "Weekly"}
                  background="dark"
                  title={`Upcoming Events\n(Week ${moment().week()} of ${new Date().getFullYear()})`}
                >
                  {/* TODO: Map Events */}
                  <ul>
                    {dashboardData && dashboardData.upcoming_events
                      ? dashboardData.upcoming_events.map((event) => (
                          <li key={event.id}>
                            <p className="text-green">{event.name}</p>
                          </li>
                        ))
                      : null}
                  </ul>
                </DashboardCard>
                <DashboardCard
                  show={selectedFilter === "Weekly"}
                  background="dark"
                  title={`Upcoming Runsheets\n(Week ${moment().week()} of ${new Date().getFullYear()})`}
                ></DashboardCard>
                <DashboardCard background="dark" title={`Active EOIs`}>
                  <ul>
                    {/* TODO: Map EOIs */}
                    {dashboardData && dashboardData.active_eoi
                      ? dashboardData.active_eoi.map((eoi, i) => (
                          <li key={i}>
                            <p className="text-green">{eoi.eoi_event_name}</p>
                          </li>
                        ))
                      : null}
                  </ul>
                </DashboardCard>
                <DashboardCard
                  background="dark"
                  title={`Recent Applications on EOIs`}
                ></DashboardCard>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const DashboardSubCard = ({ title, children }) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <h4 className="sub-card-heading">{title}</h4>
      {children}
    </div>
  );
};

export default OrganiserDashboard;
