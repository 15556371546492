import React, { Component } from "react";
import { connect } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import * as Sentry from "@sentry/react";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { generateWeeks } from "./utils";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { AWS_URL } from "../../assets/Constants";

const today = new Date();
const sixMonthsFromToday = new Date();
sixMonthsFromToday.setMonth(today.getMonth() + 6);

class OperatorRunSheets extends Component {
  state = {
    bookingRequests: [],
    startDate: new Date(),
    endDate: sixMonthsFromToday,
    weeks: generateWeeks(new Date(), sixMonthsFromToday),
    selectedStartWeek: null,
    selectedEndWeek: null,
    selectedFilterType: "week",
    selectedYear: new Date().getFullYear(),
    selectedMonth: null,
    selectedVendorId: "0",
    vendorIds: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchVendorIds();
    this.fetchRunSheets();
  }

  fetchVendorIds = () => {
    this.setState({ isLoading: true });
    fetch(`${config.BASE_URI}${apiPaths.adminData}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          body: {
            query_type: "get_operator_details_for_stall_ids",
            operator_id: JSON.parse(localStorage.getItem("Operator"))[0].id,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            vendorIds: [{ user_ptr_id: "0", title: "All Stalls" }, ...result.stalls],
            selectedVendorId: "0",
          });
        } else {
          console.error(result.error);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching vendor IDs:", error);
        this.setState({ isLoading: false });
      });
  };

  fetchRunSheets = () => {
    const {
      selectedFilterType,
      weeks,
      selectedStartWeek,
      selectedEndWeek,
      startDate,
      endDate,
      selectedYear,
      selectedMonth,
      selectedVendorId,
    } = this.state;

    let filterData = {};

    if (selectedFilterType === "week") {
      filterData = {
        start_date: weeks[selectedStartWeek]?.start,
        end_date: weeks[selectedEndWeek]?.end,
      };
    } else if (selectedFilterType === "date") {
      filterData = { start_date: startDate, end_date: endDate };
    } else if (selectedFilterType === "month") {
      const year = selectedYear;
      const month = selectedMonth;
      filterData = {
        start_date: moment().year(year).month(month).startOf("month").toDate(),
        end_date: moment().year(year).month(month).endOf("month").toDate(),
      };
    } else if (selectedFilterType === "year") {
      const year = selectedYear;
      filterData = {
        start_date: moment().year(year).startOf("year").toDate(),
        end_date: moment().year(year).endOf("year").toDate(),
      };
    }

    this.setState({ isLoading: true });

    fetch(`${config.BASE_URI}${apiPaths.operatorData}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          body: {
            query_type: "get_run_sheets_for_stalls_operator",
            vendor_id: selectedVendorId === "0" ? "0" : selectedVendorId,
            start_date: filterData.start_date,
            end_date: filterData.end_date,
            operator_id: JSON.parse(localStorage.getItem("Operator"))[0].id,
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            bookingRequests: result.data.sort((a, b) => {
              const startDateA = moment(a.event_date);
              const startDateB = moment(b.event_date);
              if (startDateA.isBefore(startDateB)) return -1;
              if (startDateA.isAfter(startDateB)) return 1;
              return 0;
            }),
          });
        } else {
          console.error(result.error);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching run sheets:", error);
        this.setState({ isLoading: false });
      });
  };

  setPresetFilter = (preset) => {
    const presets = {
      today: { start_date: moment().startOf("day"), end_date: moment().endOf("day") },
      yesterday: {
        start_date: moment().subtract(1, "days").startOf("day"),
        end_date: moment().subtract(1, "days").endOf("day"),
      },
      this_week: { start_date: moment().startOf("week"), end_date: moment().endOf("week") },
      last_week: {
        start_date: moment().subtract(1, "week").startOf("week"),
        end_date: moment().subtract(1, "week").endOf("week"),
      },
      this_month: { start_date: moment().startOf("month"), end_date: moment().endOf("month") },
      last_month: {
        start_date: moment().subtract(1, "month").startOf("month"),
        end_date: moment().subtract(1, "month").endOf("month"),
      },
      this_year: { start_date: moment().startOf("year"), end_date: moment().endOf("year") },
      last_year: {
        start_date: moment().subtract(1, "year").startOf("year"),
        end_date: moment().subtract(1, "year").endOf("year"),
      },
    };

    const selectedPreset = presets[preset];
    if (selectedPreset) {
      this.setState(
        {
          startDate: selectedPreset.start_date.toDate(),
          endDate: selectedPreset.end_date.toDate(),
          selectedFilterType: "date",
        },
        this.fetchRunSheets
      );
    }
  };

  renderFilterSection() {
    const {
      weeks,
      selectedStartWeek,
      selectedEndWeek,
      selectedFilterType,
      selectedYear,
      selectedMonth,
      startDate,
      endDate,
      vendorIds,
      selectedVendorId,
    } = this.state;

    return (
      <Row className="filter-section mb-4">
        <Col xs={12} className="mb-3">
          <Form.Group>
            <Form.Label>Select Stall</Form.Label>
            <Form.Control
              as="select"
              value={selectedVendorId}
              onChange={(e) => this.setState({ selectedVendorId: e.target.value })}
            >
              {vendorIds.map((vendor) => (
                <option key={vendor.user_ptr_id} value={vendor.user_ptr_id}>
                  {vendor.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs={12} className="mb-3">
          <Form.Group>
            <Form.Label>Filter By</Form.Label>
            <Form.Control
              as="select"
              value={selectedFilterType}
              onChange={(e) => this.setState({ selectedFilterType: e.target.value })}
            >
              <option value="week">Week Period</option>
              <option value="date">Date Range</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {selectedFilterType === "week" && (
          <>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Start Week</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedStartWeek || ""}
                  onChange={(e) => this.setState({ selectedStartWeek: e.target.value })}
                >
                  <option value="" disabled>Select Start Week</option>
                  {weeks.map((week, index) => (
                    <option key={index} value={index}>{week.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>End Week</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedEndWeek || ""}
                  onChange={(e) => this.setState({ selectedEndWeek: e.target.value })}
                >
                  <option value="" disabled>Select End Week</option>
                  {weeks.map((week, index) => (
                    <option key={index} value={index}>{week.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )}

        {selectedFilterType === "date" && (
          <>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => this.setState({ startDate: date })}
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </>
        )}

        {selectedFilterType === "month" && (
          <>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Month</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedMonth || ""}
                  onChange={(e) => this.setState({ selectedMonth: e.target.value })}
                >
                  <option value="" disabled>Select Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>{moment().month(i).format("MMMM")}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )}

        {selectedFilterType === "year" && (
          <>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Year</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedYear || ""}
                  onChange={(e) => this.setState({ selectedYear: e.target.value })}
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <option key={i} value={new Date().getFullYear() - i}>
                      {new Date().getFullYear() - i}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )}

        <Col xs={12} className="d-flex justify-content-between mt-3">
          <div className="preset-buttons">
            <Button variant="secondary" onClick={() => this.setPresetFilter("today")}>Today</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("yesterday")}>Yesterday</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_week")}>This Week</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_week")}>Last Week</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_month")}>This Month</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_month")}>Last Month</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_year")}>This Year</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_year")}>Last Year</Button>
          </div>
          <Button variant="primary" onClick={this.fetchRunSheets}>Apply</Button>
        </Col>
      </Row>
    );
  }

  renderBookingRequests() {
    const { bookingRequests, isLoading } = this.state;

    if (isLoading) {
      return <Spinner animation="border" />;
    }

    if (!bookingRequests.length) {
      return <p>No booking requests found.</p>;
    }

    return bookingRequests.map((item, index) => (
      <Accordion key={index} className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <Row>
              <Col xs={3}>Event Date</Col>
              <Col xs={3}>Vendor Title</Col>
              <Col xs={3}>Location</Col>
              <Col xs={3}>Event Name</Col>
            </Row>
            <Row>
              <Col xs={3}>{item.event_date}</Col>
              <Col xs={3}>{item.vendor__title}</Col>
              <Col xs={3}>{item.location}</Col>
              <Col xs={3}>{item.event_name}</Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row><Col><strong>Bump In:</strong> {item.bump_in_date} {item.bump_in_start_time} - {item.bump_in_end_time}</Col></Row>
              <Row><Col><strong>Bump Out:</strong> {item.bump_out_date} {item.bump_out_start_time} - {item.bump_out_end_time}</Col></Row>
              <Row><Col><strong>Truck Manager:</strong> {item.truck_manager}</Col></Row>
              <Row><Col><strong>Site Contact:</strong> {item.site_contact} ({item.site_contact_number})</Col></Row>
              <Row><Col><strong>Power:</strong> {item.power}</Col></Row>
              <Row><Col><strong>Other Notes:</strong> {item.other_notes}</Col></Row>
              <Row><Col><strong>Beverage Notes:</strong> {item.beverage_notes}</Col></Row>
              <Row><Col><strong>Vouchers Notes:</strong> {item.vouchers_notes}</Col></Row>
              <Row><Col><strong>Multiple Day Trading Options:</strong> {item.multiple_day_trading_options}</Col></Row>
              <Row><Col><strong>Trading Time Options:</strong> {item.multiple_day_trading_time_options}</Col></Row>
              <Row><Col><strong>Bump In Out Notes:</strong> {item.bump_in_out_notes}</Col></Row>
              <Row>
  <Col>
    {item.first_additional_document_name && item.first_addition_document_option && (
      <>
        <strong>Additional Document 1:</strong>{" "}
        <a
          href={`${AWS_URL}/${item.first_addition_document_option}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.first_additional_document_name}
        </a>
        <br />
      </>
    )}
    
  </Col>
  
</Row>
<Row>
  <Col>
    {item.second_additional_document_name && item.second_additional_document_name && (
      <>
        <strong>Additional Document 2:</strong>{" "}
        <a
          href={`${AWS_URL}/${item.second_addition_document_option}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.second_additional_document_name}
        </a>
        <br />
      </>
    )}
    
  </Col>
  
</Row>
<Row>
  <Col>
    {item.third_addition_document_name && item.third_addition_document_name && (
      <>
        <strong>Additional Document 3:</strong>{" "}
        <a
          href={`${AWS_URL}/${item.third_addition_document_option}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.third_addition_document_name}
        </a>
        <br />
      </>
    )}
    
  </Col>
  
</Row>

              <PDFDownloadLink
                document={<RunsheetPdfCreator item={item} />}
                fileName={`runsheet-${item.vendor__title}.pdf`}
              >
                {({ loading }) => (loading ? 'Generating PDF...' : 'Download Runsheet')}
              </PDFDownloadLink>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    ));
  }

  render() {
    return (
      <div className="operator-run-sheets">
        <VendorNavBar />
        <Container>
          <h2 className="text-center my-4">Event Run Sheets</h2>
          {this.renderFilterSection()}
          <div className="booking-requests mt-4">
            {this.renderBookingRequests()}
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.appReducer.loggedIn,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorRunSheets, {
    fallback: <ErrorFallbackComponent />,
  })
);
